import React from 'react';
import ReactDOM from 'react-dom';
import App from './views/app';
import { Provide } from './state';
const rootElement = document.getElementById('root');

const app = (
    <Provide>
        <App/>
    </Provide>
)

ReactDOM.render(app, rootElement)
// ReactDOM.render(<React.StrictMode><App/></React.StrictMode>, rootElement)