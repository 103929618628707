import React/*, { useState, useEffect }*/ from 'react'
import Base from '../../components/Base'
import './style.scss'


function Home({}: {}) {
    return (
        <Base/>
    )
}

export default Home
