import RESTRoutes from './routes'
import axios from 'axios'
import { store } from '../../state';
import { Main } from '../../state/models';

class Query {
    /**
     * Create a query string in form of ?key1=value1&key2=value2
     * @param queries - array or object of key - value pairs
    */
	static create = (
		queries: object | Array<{ key: string; value: string }>
	): string => {
		let q = '?';

		if (Array.isArray(queries)) {
			for (const item of queries) {
				q += `${item.key}=${item.value}&`;
			}
			return q.substring(0, q.length - 1);
		}

		const keys = Object.keys(queries);
		if (!keys.length) return '';
		for (let i = 0; i < keys.length; i++) {
			q += `${keys[i]}=${queries[keys[i]]}`;
			if (i < keys.length - 1) {
				q += '&';
			}
		}

		return q;
	};
    /**
     * convert a query string in form of ?key1=value1&key2=value2 to an object
    */
   static decode = <T = any>(queryString: string): T => {
       queryString = decodeURIComponent(queryString);
		const keyValuePairs: string[] = queryString
			? queryString.split('?')[1].split('&')
			: [];
		const obj = {} as T;
		for (const item of keyValuePairs) {
            const [key, value] = item.split('=');

            if (key in obj) {
                const prevValues = typeof obj[key] === 'object' ? [...obj[key]]: [obj[key]];
                obj[key] = [
                    ...prevValues,
                    value
                ]
            } else {
                obj[key] = value;
            }
		}
		return obj;
	};
}

export default class Rest {
    private id: string;

    constructor(id: string) {
        this.id = id;
    }

    public request(type, requestName, params, additionalConfig?, callback?, loader?) {
        const route = RESTRoutes.find(r => r.name === requestName);
        if (!route) {
            throw new Error('Requested route does not exist.');
        }
        if (route && (route.hasLoader || loader)) {
            store.update((main: Main) => {
                main.loaders.rest.push(route.name)
                return main
            })
        }
        const config = {
            ...additionalConfig,
            method: type,
            baseURL: this.id,
            url: route.url
        };
        if (type === 'get' || type === 'delete') {
            const q = params ? Query.create(params) : '';
            config.url += q;
        } else {
            config['data'] = params;
        }
        return axios(config)
            .then(response => {
                route.mapper(response)
                if (route.hasLoader || loader) {
                    store.update((main: Main) => {
                        const newLoaders = main.loaders.rest.filter(i => i !== route.name)
                        main.loaders.rest = newLoaders
                        return main
                    })
                }
                if (callback) {
                    callback(response)
                }
                return response;
            })
            .catch(e => {
                console.warn(e);
                return e;
            });
    }
}