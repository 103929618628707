import React from 'react'
// import routes from '../routes';
// import { Route, BrowserRouter, Routes } from 'react-router-dom'
import Home from '../views/pages/Home'
import { hot } from 'react-hot-loader'
import './styles/app.scss'
import './styles/basics.scss'
import '../../node_modules/react-table/react-table.css'
import '../../node_modules/react-chessground/dist/styles/chessground.css'
import '../../node_modules/react-chessground/dist/styles/theme.css'

function App() {
    return <Home/>
    /*
    return (
        <BrowserRouter>
            <Routes>
                {routes.map(route =>
                    <Route
                        key={route.path}
                        path={route.path}
                        // exact={route.exact}
                        element={(props: any) =>
                            route.component
                            // React.createElement(route.component, {...props})
                        }
                    />)
                }
            </Routes>
        </BrowserRouter>
    );
    */
}

declare const module: object
export default hot(module)(App)