import RestAPI from './types';
// import { store } from '../../state';
// import { fillRegionsWithLeagues, fillMainMarkets, fillMainMarket, groupHeaderMatches, fillCorrectScoreOdds, fillBetrixSelectionOdds, createMultiSystemsPayout, setPopupContent, sortByProperty } from '../../utils';
// import { getResponseMessage } from '../../strings';

type RestRoute = Array<
/*
    | RestAPI.RouteDefinition<'get-authorization'>
    | RestAPI.RouteDefinition<'get-available-leagues'>
    | RestAPI.RouteDefinition<'get-available-matches'>
    | RestAPI.RouteDefinition<'get-available-matches-today'>
    | RestAPI.RouteDefinition<'get-available-sports'>
    | RestAPI.RouteDefinition<'get-betrix-bet'>
    | RestAPI.RouteDefinition<'get-betrix-combo'>
    | RestAPI.RouteDefinition<'get-betslips'>
    | RestAPI.RouteDefinition<'get-betslip-items'>
    | RestAPI.RouteDefinition<'get-betslip-subitems'>
    | RestAPI.RouteDefinition<'get-cashout'>
    | RestAPI.RouteDefinition<'get-custom-betrix-combo'>
    | RestAPI.RouteDefinition<'get-doublettes'>
    | RestAPI.RouteDefinition<'get-favourite-leagues'>
    | RestAPI.RouteDefinition<'get-header-matches'>
    | RestAPI.RouteDefinition<'get-look-and-feel'>
    | RestAPI.RouteDefinition<'get-main-market'>
    | RestAPI.RouteDefinition<'get-multi-systems-betslip'>
    | RestAPI.RouteDefinition<'get-odds'>
    | RestAPI.RouteDefinition<'get-odds-bet-id'>
    | RestAPI.RouteDefinition<'get-odds-size'>
    | RestAPI.RouteDefinition<'get-odds-today'>
    | RestAPI.RouteDefinition<'get-suggested-markets'>
    | RestAPI.RouteDefinition<'insert-account-register'>
    | RestAPI.RouteDefinition<'insert-betrix-bet'>
    | RestAPI.RouteDefinition<'insert-betslip'>
    | RestAPI.RouteDefinition<'insert-cashout'>
    | RestAPI.RouteDefinition<'insert-market-maker-odds'>
    | RestAPI.RouteDefinition<'insert-multi-systems'>
    | RestAPI.RouteDefinition<'insert-multi-systems-betslip'>
    | RestAPI.RouteDefinition<'insert-system-single'>
    | RestAPI.RouteDefinition<'insert-system-single-betslip'>
    | RestAPI.RouteDefinition<'update-football-model'>
*/
| RestAPI.RouteDefinition<'register'>
| RestAPI.RouteDefinition<'login'>
| RestAPI.RouteDefinition<'position'>
| RestAPI.RouteDefinition<'insert-position'>
| RestAPI.RouteDefinition<'delete-position'>
| RestAPI.RouteDefinition<'update-position'>
// | RestAPI.RouteDefinition<'positions'>
// | RestAPI.RouteDefinition<'positions-count'>
| RestAPI.RouteDefinition<'positionposition'>
| RestAPI.RouteDefinition<'position2'>
| RestAPI.RouteDefinition<'position0'>
| RestAPI.RouteDefinition<'setposition'>
| RestAPI.RouteDefinition<'customer'>
| RestAPI.RouteDefinition<'toggle-customer'>
| RestAPI.RouteDefinition<'group'>
| RestAPI.RouteDefinition<'update-group'>
| RestAPI.RouteDefinition<'insert-move'>
| RestAPI.RouteDefinition<'move'>
| RestAPI.RouteDefinition<'update-move'>
| RestAPI.RouteDefinition<'delete-move'>
| RestAPI.RouteDefinition<'set-text'>
>

const RESTRoutes: RestRoute = [
    {
        name: 'register',
        url: '/register',
        mapper: () => void 0
    },
    {
        name: 'login',
        url: '/login',
        mapper: () => void 0
    },
    {
        name: 'position',
        url: '/position',
        mapper: () => void 0
    },
    {
        name: 'set-text',
        url: '/text',
        mapper: () => void 0
    },
    {
        name: 'move',
        url: '/move',
        mapper: () => void 0
    },
    {
        name: 'insert-position',
        url: '/position',
        mapper: () => void 0
    },
    {
        name: 'delete-position',
        url: '/position',
        mapper: () => void 0
    },
    {
        name: 'delete-move',
        url: '/move',
        mapper: () => void 0
    },
    {
        name: 'update-position',
        url: '/position',
        mapper: () => void 0
    },
    {
        name: 'update-move',
        url: '/move',
        mapper: () => void 0
    },
/*
    {
        name: 'positions',
        url: '/positions',
        mapper: () => void 0
    },

    {
        name: 'positions-count',
        url: '/positions-count',
        mapper: () => void 0
    },
*/
    {
        name: 'positionposition',
        url: '/positionposition',
        mapper: () => void 0
    },
    {
        name: 'position2',
        url: '/position2',
        mapper: () => void 0
    },
    {
        name: 'position0',
        url: '/position0',
        mapper: () => void 0
    },
    {
        name: 'setposition',
        url: '/pos',
        mapper: () => void 0
    },    
    {
        name: 'customer',
        url: '/customer',
        mapper: () => void 0
    },
    {
        name: 'toggle-customer',
        url: '/customer',
        mapper: () => void 0
    },
    {
        name: 'group',
        url: '/group',
        mapper: () => void 0
    },
    {
        name: 'update-group',
        url: '/group',
        mapper: () => void 0
    },
    {
        name: 'insert-move',
        url: '/move',
        mapper: () => void 0
    },
/*
    {
        name: 'get-authorization',
        url: '/authorize',
        mapper: () => void 0
    },
    {
        hasLoader: true,
        name: 'get-available-leagues',
        url: '/available-leagues',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    const regions = fillRegionsWithLeagues(response.data)
                    main.feed.leagues = response.data
                    main.feed.regions = regions
                    return main
                })
            }
        }
    },
    {
        hasLoader: true,
        name: 'get-available-matches',
        url: '/available-matches',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    main.feed.matches = response.data
                    return main
                })
            }
        }
    },
    {
        hasLoader: true,
        name: 'get-available-matches-today',
        url: '/available-matches-today',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    main.feed.matchesToday = response.data
                    return main
                })
            }
        }
    },
    {
        hasLoader: true,
        name: 'get-available-sports',
        url: '/available-sports',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    main.feed.sports = response.data
                    return main
                })
            }
        }
    },
    {
        name: 'get-betrix-bet',
        url: '/betrix-bet',
        mapper: response => {
            if (response.data.resolved) {
                store.update(main => {
                    main.betrix.bet = response.data.betrixBet
                    main.betrix.leaderboard = response.data.betrixLeaderboard
                    main.betrix.optInPrice = response.data.price
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'get-betrix-combo',
        url: '/betrix-combo',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    main.betrixCombo.settings.quick = response.data.filter(d => !d.disabled);
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'get-betslips',
        url: '/betslip',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    main.betslip.history.standard = sortByProperty(response.data, 'insertedat').reverse()
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'get-betslip-items',
        url: '/betslip-item',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    main.betslip.history.items = response.data
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'get-betslip-subitems',
        url: '/betslip-subitem',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    main.betslip.history.subitems = response.data
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'get-cashout',
        url: '/cashout',
        mapper: response => {
            if (response.data.resolved) {
                store.update(main => {
                    main.betslip.cashout = response.data.cashout
                    return main
                })
            }
        }
    },
    {
        hasLoader: true,
        name: 'get-custom-betrix-combo',
        url: '/custom-betrix-combo-new',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    main.betrixCombo.custom = response.data.data
                    return main
                })
            }
        }
    },
    {
        hasLoader: true,
        name: 'get-doublettes',
        url: '/doublettes',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    main.betrix.doublettes = response.data
                    return main
                })
            }
        }
    },
    {
        hasLoader: true,
        name: 'get-favourite-leagues',
        url: '/favourite-leagues',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    const favouriteLeaguesStorage = localStorage.getItem('favouriteLeagues')
                    const favouriteLeagues = (favouriteLeaguesStorage) ? JSON.parse(favouriteLeaguesStorage) : response.data.data
                    main.feed.favouriteLeagues.data = favouriteLeagues
                    return main
                })
            }
        }
    },
    {
        hasLoader: true,
        name: 'get-header-matches',
        url: '/header-matches',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    main.feed.headerMatches = groupHeaderMatches(response.data)
                    return main
                })
            }
        }
    },
    {
        hasLoader: true,
        name: 'get-look-and-feel',
        url: '/look-and-feel',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    main.settings = response.data
                    return main
                })
            }
        }
    },
    {
        hasLoader: true,
        name: 'get-main-market',
        url: '/main-market',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    main.feed.mainMarkets = response.data
                    return main
                })
            }
        }
    },
    {
        hasLoader: true,
        name: 'get-multi-systems-betslip',
        url: '/multi-systems-betslip',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    main.betrixCombo.settings.betslip = response.data.filter(d => !d.disabled);
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'get-odds',
        url: '/odds',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    main.feed.odds = fillMainMarkets(response.data, main.feed.odds)
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'get-odds-bet-id',
        url: '/odds-bet-id',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    main.feed.odds = fillMainMarkets(response.data, main.feed.odds)
                    return main
                })
            }
        }
    },
    {
        hasLoader: true,
        name: 'get-odds-size',
        url: '/odds-size',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    main.feed.oddsSize = response.data
                    return main
                })
            }
        }
    },
    {
        hasLoader: true,
        name: 'get-odds-today',
        url: '/odds-today',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    main.feed.oddsToday = fillMainMarket(response.data)
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'get-suggested-markets',
        url: '/betrix-markets',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    main.betrix.suggestedMarkets = response.data
                    return main
                })
            }
        }
    },
    {
        name: 'insert-account-register',
        url: '/account-register',
        mapper: () => void 0
    },
    {
        name: 'insert-betrix-bet',
        url: '/betrix-bet',
        mapper: () => void 0
    },
    {
        hasLoader: false,
        name: 'insert-betslip',
        url: '/betslip',
        mapper: response => {
            const message = getResponseMessage(response.data)
            setPopupContent(message)
        }
    },
    {
        hasLoader: false,
        name: 'insert-cashout',
        url: '/cashout',
        mapper: response => {
            const message = getResponseMessage(response.data)
            setPopupContent(message)
        }
    },
    {
        hasLoader: false,
        name: 'insert-market-maker-odds',
        url: '/calculate-odd',
        mapper: () => void 0
    },
    {
        hasLoader: false,
        name: 'insert-multi-systems',
        url: '/multi-systems-new',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    main.betrixCombo.multiSystems = response.data
                    main.betrixCombo.MSPayout = createMultiSystemsPayout(response.data)
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'insert-multi-systems-betslip',
        url: '/multi-systems-new',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    main.betslip.betrixCombo.MSData = response.data
                    main.betslip.betrixCombo.MSPayout = createMultiSystemsPayout(response.data)
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'insert-system-single',
        url: '/system-single',
        mapper: () => void 0
    },
    {
        hasLoader: false,
        name: 'insert-system-single-betslip',
        url: '/system-single',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    main.betslip.betrixCombo.SSData = response.data
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'update-football-model',
        url: '/football-model',
        mapper: response => {
            if (typeof response.data !== 'string') {
                store.update(main => {
                    main.betrix.correctScoreOdds = fillCorrectScoreOdds(response.data)
                    main.betrix.selectionOdds = fillBetrixSelectionOdds(response.data)
                    return main
                })
            }
        }
    }
*/
];

export default RESTRoutes;
