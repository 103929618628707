/*
import app from './stores/app'
import betrix from './stores/betrix'
import betrixCombo from './stores/betrix-combo'
import betslip from './stores/betslip'
import feed from './stores/feed'
import marketmaker from './stores/marketmaker'
import player from './stores/player'
import popup from './stores/popup'
import settings from './stores/settings'
*/
import loaders from './stores/loaders'
import Store from './manager'

import { Main } from './models'

const main: Main = {
    loaders
/*
    app,
    betrix,
    betrixCombo,
    betslip,
    feed,
    ,
    marketmaker,
    player,
    popup,
    settings
*/
}

export const store = new Store(main)
export const Provide = store.provide()
export const subscribe = store.subscribe
export const update = store.update
export const get = store.get