import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react'
import clsx from 'clsx'
import LoadingSpinner from '../LoadingSpinner'
import './style.scss'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    isLoading: boolean;
    className?: string;
}

function LoadingButton({ children, className, isLoading }: PropsWithChildren<Props>) {
    return (
        <button
            className={clsx('LoadingButton', className)}
            disabled={isLoading}
        >
            {children}
            {isLoading && <LoadingSpinner/>}
        </button>
    )
}

export default LoadingButton;