import Rest from './rest'
import RestAPI from './types'
// import { data_server_url } from '../../config.json'

const DEV = false
const server = DEV ? 'http://localhost:9092' : 'https://planica.xyz'

const data_server_url = server + '/ct'
// const data_server_url = 'https://planica.xyz/ct'

const dataServer: RestAPI.Rest = new Rest(data_server_url)

export default dataServer
