import React, { PropsWithChildren/*, useState, useEffect*/ } from 'react'
// import Loader from '../Loader'
// import { store } from '../../../state'
import './style.scss'
// import Chessboard from '../../components/Chessboard'
// import Chessboard from 'chessboardjsx'
import WithMoveValidation from '../../../integrations/WithMoveValidation';
// import Demo from '../../../integrations/Demo';

  const boardsContainer = {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    // flexWrap: "wrap",
    width: "100vw",
    paddingTop: 50,
    paddingBottom: 50,
    background: '#edebe9',// linear-gradient(to bottom, #dbd7d1, #edebe9 116px) no-repeat',
    height: "100vh"
  };

/*export default*/ function Base({/* betslipBetrixItems, betslipStandardItems, children, isPopupOpened, loaders, platform, playerData, playerToken */}: PropsWithChildren<{}>) {
/*
    const [mobileBetslipOpened, setMobileBetslipOpened] = useState(false)
    const [mobileSportSelectorOpened, setMobileSportSelectorOpened] = useState(false)

    useEffect(() => {
        window.addEventListener('resize', handlePlatform)

        return () => window.removeEventListener('resize', handlePlatform)
    }, [handlePlatform])

    function handlePlatform() {
        const newPlatform = getPlatform()
        if (newPlatform !== platform) {
            store.update(main => {
                main.app.platform = newPlatform
                return main
            })
        }
    }

    function handleMobileBetslip() {
        if (isMobile()) {
            const bodyStyle = (mobileBetslipOpened) ? 'auto' : 'hidden'
            document.body.style.overflow = bodyStyle
            setMobileBetslipOpened(!mobileBetslipOpened)
        }
    }
    
    function handleMobileSportSelector() {
        if (isMobile()) {
            const bodyStyle = (mobileSportSelectorOpened) ? 'auto' : 'hidden'
            document.body.style.overflow = bodyStyle
            setMobileSportSelectorOpened(!mobileSportSelectorOpened)
        }
    }
*/
    // const pathname = window.location.pathname
    // const splittedPathname = pathname.split('/').filter(Boolean)

    // const isMatchPage = (splittedPathname[3]) ? !isNaN(+window.atob(splittedPathname[3])) : false
    // const betslipLength = betslipBetrixItems.length + betslipStandardItems.length
    // console.log (isMatchPage)
    // const position = ''
    
    return (
        <div style={boardsContainer}>
            {/*<Demo/>*/}
            <WithMoveValidation />
          {/*({ position, onDrop }) => (
          )*/}
            {/*<Chessboard
              id="stockfish"
              position={position}
              width={320}
              // onDrop={onDrop}
              boardStyle={boardStyle}
              orientation="black"
            />*/}

      </div>

    )
{/*
        <div className="base">
<Chessboard position="start"/>*/}
            {/*checkLoader(loaders)*/}
            {/*
            <Header
                isMatchPage={isMatchPage}
                mobileSportSelectorOpened={mobileSportSelectorOpened}
                pathname={pathname}
                playerData={playerData}
                playerToken={playerToken}
                handleMobileSportSelector={handleMobileSportSelector}
            />
            <SportSelector
                handleMobileSportSelector={handleMobileSportSelector}
                mobileSelectorOpened={mobileSportSelectorOpened}
            />
            <div className={clsx('base-children', mobileSportSelectorOpened && 'sport-selector-opened', isMatchPage && 'is-match-page')}>
                <div className="main-wrapper">
                    {children}
                </div>
            </div>
            <Betslip
                handleMobileBetslip={handleMobileBetslip}
                mobileBetslipOpened={mobileBetslipOpened}
            />
            <BetslipMobileIcon
                betslipLength={betslipLength}
                handleMobileBetslip={handleMobileBetslip}
                mobileBetslipOpened={mobileBetslipOpened}
                mobileSportSelectorOpened={mobileSportSelectorOpened}
            />
            {isPopupOpened && <Popup/>}
            */}
        {/*</div>*/}
}
export default Base
// export default store.subscribe(main => ({
/*
    betslipBetrixItems: main.betslip.betrix,
    betslipStandardItems: main.betslip.standard,
    isPopupOpened: main.popup.isOpened,
    loaders: main.loaders.rest,
    platform: main.app.platform,
    playerData: main.player.data,
    playerToken: main.player.token
*/
// }))(Base)
/*
function checkLoader(loaders: string[]) {
    if (loaders.length) {
        return (
            <Loader/>
        )
    }
    return null
}
*/