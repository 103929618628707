import React, { FormEvent, Component } from 'react';
import PropTypes from 'prop-types';
import {Chess, PieceSymbol, Square, Color, SQUARES} from 'chess.js';
import Chessboard from 'chessboardjsx';
import validateFEN from 'fen-validator'
import LoadingButton from './LoadingButton';
import FaIcon from '../views/components/FaIcon'
import dataServer from '../network/rest';
import ReactTable from 'react-table'
import Chessground from 'react-chessground'
const MAX_CHARACTERS_ENG_LONG = 5000
const MAX_CHARACTERS_ENG_SHORT = 1000
const MAX_CHARACTERS_SLO_SHORT = 1000
// import { roughSquare } from './CustomRough';
// display smaller positions when position is selected. display main position in different color if it has subpositions
// piece has to be anulled somehow and maybe init to empty string
const colors: ColorAbbr[] = ['i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
type ColorType = 'w' | 'b' | ''
type PieceType = 'p' | 'n' | 'b' | 'r' | 'q' | 'k' | ''

import slo from '../assets/slovenia-flag.png'
import uk from '../assets/united-kingdom-flag.png'
import usa from '../assets/usa-flag.png'

import pawn from '../assets/wp_.svg'
import Pawn from '../assets/bp_.svg'

import king from '../assets/wk_.svg'
import King from '../assets/bk_.svg'

import queen from '../assets/wq_.svg'
import Queen from '../assets/bq_.svg'

import rook from '../assets/wr_.svg'
import Rook from '../assets/br_.svg'

import bishop from '../assets/wb_.svg'
import Bishop from '../assets/bb_.svg'

import knight from '../assets/wn_.svg'
import Knight from '../assets/bn_.svg'

enum PIECE {
  KING,
  QUEEN,
  ROOK,
  BISHOP,
  KNIGHT,
  PAWN
}

const whitePieces: string[] = ['♔', '♕', '♖', '♗', '♘', '♙']
// const blackPieces: string[] = ['♚', '♕', '♜', '♝', '♞', '♟']

type ColorAbbr = 'i'|'j'|'k'|'l'|'m'|'n'|'o'|'p'|'q'|'r'|'s'|'t'|'u'|'v'|'w'|'x'|'y'|'z'|'0'|'1'|'2'|'3'|'4'|'5'|'6'|'7'|'8'|'9'

interface StrategyArrow {
  startSquare: string
  endSquare: string
  color: string
}

interface StrategyColor {
  name: string
  color: string 
}

interface Strategy {
  which: number
  line: number
  item: number
  arrow: StrategyArrow[]
  square: StrategyColor[]
  squares: StrategyColor[]
}

interface Props {
  children
}
interface Message {
  mate: string
  stalemate: string
  threefold: string
  material: string
  draw: string
  congratulations: string
  better: string
  again: string
  playForWin: string
  playForDraw: string
  playForMate: string
  goForward: string
  goBackward: string
  refresh: string
  goForwardBanned: string
  white: string
  black: string
  signout: string
  user: string
  instruction: string
  chapter: string
  win: string
}

interface Messages {
  en: Message
  sl: Message
}

const messages: Messages = {
  en: {
    mate: 'Checkmate',
    stalemate: 'Stalemate',
    threefold: 'Threefold repetition',
    material: 'Insufficient material',
    draw: 'Fifty-move rule',
    congratulations: 'Congratulations!',
    better: 'A better move exists.',
    again: 'Refresh and try again!',
    playForWin: 'play for win',
    playForDraw: 'play for draw',
    playForMate: 'checkmate',
    goForward: 'go forward',
    goBackward: 'go backward',
    refresh: 'load again the initial position',
    goForwardBanned: 'it is not allowed to go forward, until the puzzle is not solved',
    white: 'white',
    black: 'black',
    signout: 'sign out',
    user: 'user',
    instruction: 'instruction',
    chapter: 'chapter/puzzle',
    win: 'Victory! Go forward'
  },
  sl: {
    mate: 'Mat',
    stalemate: 'Pat',
    threefold: 'Trikrat ponovljena pozicija',
    material: 'Ni dovolj materiala',
    draw: 'Pravilo 50 potez',
    congratulations: 'Čestitke!',
    better: 'Obstaja boljša poteza.',
    again: 'Osveži in poskusi znova!',
    playForWin: 'igraj na zmago',
    playForDraw: 'igraj na remi',
    playForMate: 'matiraj',
    goForward: 'pojdi naprej',
    goBackward: 'pojdi nazaj',
    refresh: 'naloži znova začetno pozicijo',
    goForwardBanned: 'ni dovoljeno iti naprej, dokler naloga ni končana',
    white: 'beli',
    black: 'črni',
    signout: 'odjavi se',
    user: 'uporabnik',
    instruction: 'navodilo',
    chapter: 'poglavje/naloga',
    win: 'Zmaga! Pojdi naprej.'
  }
}

export interface Customer {
  uuid: string
  username: string
  positionuuid: string
  admin: boolean
  email: string
  groupuuid: string
  position: number
  group?: string
}

export interface Group {
  uuid: string
  name: string
  namesl: string
  prevuuid: string | null
  nextuuid: string | null
  count: number
  position: number
}

export interface Move {
  id: number
  fen: string
  source: Square | ''
  target: Square | ''
  piece: PieceType
  color: ColorType
  positionuuid: string
}

export interface Position {
  uuid: string
  prevuuid: string | null
  nextuuid: string | null
  fen: string
  variant: string
  win: boolean
  mate: boolean
  move: Move[]
  textenglong: string
  textengshort: string
  textslshort: string
}

const lines: string[] = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h']

interface ActiveDiagram {
  which: number
  line: number
  item: number
  n: number
}

interface State {
  strategy: Strategy[]
  arrows: StrategyArrow[]
  squares: StrategyColor[]
  squareColor: StrategyColor[]
  activeDiagram: ActiveDiagram
  textslshort: string
  textengshort: string
  textenglong: string
  firstMove: boolean
  adminMode: boolean
  Variant: string
  variant: string
  mode: boolean
  positionIndex: number
  move?: Move[]
  pNum: number
  matingSquare: string
  stalemateSquare: string
  promotionSourceSquare?: string
  promotionTargetSquare?: string
  promotionSquares: string[]
  promotionCandidate: boolean
  promotion: boolean
  language: 'en' | 'sl'
  playAllowed: boolean
  bestMoveFrom: string
  bestMoveTo: string
  background: boolean
  orientation: ColorType
  positionUuid?: string
  Fen?: string
  getPosition?
  playForWin: boolean
  playForMate: boolean
  playForMateMoves: number
  groupIndex: number
  newFen: string
  newVariant: string
  newWin: boolean
  newMate: boolean
  position: Position[]
  positionposition: number
  group: Group[]
  customer: Customer[]
  admin?: boolean
  Admin?: boolean
  groupname?: string
  groupnamesl?: string
  grouppos: number
  groupPosition?: number
  turn: ColorType
  message?: string
  message2?: string
  groupsuuid?: string
  groupuuid?: string
  prevuuid?: string
  uuid?: string
  nextuuid?: string
  positionuuid?: string
  user?: string
  customeruuid?: string
  fen?: string
  dropSquareStyle
  squareStyles
  pieceSquare: string
  history: any[]
  password: string
  username: string
  newusername: string
  password1: string
  password2: string
  email: string
  isLoading: boolean
  responseMessage: string
}

class HumanVsHuman extends Component<Props, State> {
  static propTypes = { children: PropTypes.func };
  private chess: Chess
  private engine: Worker
  private Engine: Worker
  constructor(props: any) {
    super(props)
    this.chess = new Chess();
    this.state = {
      strategy: [],
      arrows: [],
      squares: [],
      squareColor: [],
      activeDiagram: {which: -1, line: -1, item: -1, n: -1},
      textslshort: '',
      textengshort: '',
      textenglong: '',
      positionposition: 1,
      firstMove: true,
      adminMode: true,
      Variant: '',
      variant: '',
      mode: true, 
      grouppos: 0,
      positionIndex: -1,
      matingSquare: '',
      stalemateSquare: '',
      pNum: 0,
      promotionSquares: [],
      promotionCandidate: false,
      promotion: false,
      language: 'sl',
      bestMoveFrom: '',
      bestMoveTo: '',
      playForMateMoves: 0,
      playForMate: true,
      background: false,
      orientation: 'w',
      playForWin: true,
      playAllowed: true,
      groupIndex: -1,
      newFen: '',
      newVariant: '',
      newWin: true,
      newMate: true,
      position: [],
      group: [],
      customer: [],
      turn: 'w',
      password: '',
      username: '',
      newusername: '',
      password1: '',
      password2: '',
      email: '',
      isLoading: false,
      responseMessage: '',
      dropSquareStyle: {}, // square styles for active drop square
      squareStyles: {}, // custom square styles
      pieceSquare: '', // square with the currently clicked piece
      // square: '', // currently clicked square
      history: [] // array of past game moves
    };
  }

  public send(fen) {
    this.engine.postMessage(fen);
  }

  public Send(fen) {
    this.Engine.postMessage(fen);
  }

  public send2(fen) {
    let found = false
    if (this.state.move) {
      for (const item of this.state.move) {
        const index: number = item.fen.indexOf(' ')
        if (index !== -1) {
          const piece: {type: 'k'|'q'|'r'|'b'|'n'|'p'|'', color: 'b'|'w'} = this.chess.get(item.source as Square)

          if (piece && item.piece === piece.type && item.color === piece.color && item.positionuuid === this.state.positionuuid && item.fen.substring(0, index + 2) === fen.substring(0, index + 2)) {
            found = true
            this.chess.move({ from: item.source, to: item.target })
            this.setState(({ history, pieceSquare }) => ({
              squareColor: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].square : [],
              arrows: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].arrow : [],
              squares: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].squares : [],
              activeDiagram: {which: -1, line: -1, item: -1, n: this.state.activeDiagram.n + 1},
              fen: this.chess.fen(),
              history: this.chess.history({ verbose: true }),
              squareStyles: squareStyling({ pieceSquare, history, matingSquare: this.state.matingSquare, stalemateSquare: this.state.stalemateSquare
              , squareColor: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].square : []
            })
            }));
            break
          }
        }
      }
    }
    if (!found) {
      this.send('position fen ' + fen);
      this.send('go depth 10')
    }
  }

  public getMatingSquare(c, stalemate?: boolean): string {
    if (c && (stalemate === undefined && c.isCheckmate() || stalemate && c.isStalemate())) {
      for (let i = 0; i < 8; i++) {
        for (let j = 1; j <= 8; j++) {
          const square: { type: 'k' | 'q' | 'r' | 'b' | 'n' | 'p', color: ColorType } = c.get(lines[i] + j)
          if (square && square.type === 'k' && square.color === c.turn()) {
            return lines[i] + j
          }
        }
      }
    }
    return ''
  }

  public componentDidMount() {
    this.Engine = new Worker('./stockfish.js');
    this.engine = new Worker('./stockfish.js');
   
    this.Engine.onmessage = (line) => {
      if (line.data.indexOf('mate') > -1) {
        const mate: string[] = line.data.match(/mate\s+(\S+)/);
        if (mate) {
          this.setState({
            playForMateMoves: +mate[1]
          })
        }
      }

      if (line.data.indexOf('bestmove') > -1) {
        const match: string[] = line.data.match(/bestmove\s+(\S+)/);
        if (match && match[1]) {
            this.setState({
              bestMoveFrom: match[1].substring(0, 2),
              bestMoveTo: match[1].substring(2, 4),
            })
        }
      }
    }

    // engine - this engine moves for the opponent
    this.engine.onmessage = (line) => {
      if (line.data.indexOf('bestmove') > -1) {
        const match: string[] = line.data.match(/bestmove\s+(\S+)/);
        if (match) {
          this.chess.move({
            from: match[1].substring(0, 2),
            to: match[1].substring(2, 4)
          })

          this.setState(({ history, pieceSquare }) => ({
            squareColor: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].square : [],
            arrows: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].arrow : [],
            squares: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].squares : [],
            activeDiagram: {which: -1, line: -1, item: -1, n: this.state.activeDiagram.n + 1},
            fen: this.chess.fen(),
            history: this.chess.history({ verbose: true }),
            squareStyles: squareStyling({ pieceSquare, history, matingSquare: this.state.matingSquare, stalemateSquare: this.state.stalemateSquare
            , squareColor: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].square : []
          })
          }));
          if (this.chess.isCheckmate()) {
            const matingSquare: string = this.getMatingSquare(this.chess)
            if (this.state.playForWin && this.state.turn !== this.chess.turn()) {
              this.setState({ matingSquare, squareStyles: { [matingSquare]: { background: 'crimson' } }, message: messages[this.state.language].congratulations, message2: messages[this.state.language].mate })
            } else {
              this.setState({ matingSquare, squareStyles: { [matingSquare]: { background: 'crimson' } }, message: messages[this.state.language].mate })
            }
          } else if (this.chess.isStalemate()) {
            const stalemateSquare: string = this.getMatingSquare(this.chess, true)

            if (!this.state.playForWin) {
              this.setState({ stalemateSquare, squareStyles: { [stalemateSquare]: { background: 'coral' } }, message: messages[this.state.language].congratulations, message2: messages[this.state.language].stalemate })
            } else {
              this.setState({ stalemateSquare, squareStyles: { [stalemateSquare]: { background: 'coral' } }, message: messages[this.state.language].stalemate })
            }
          } else if (this.chess.isThreefoldRepetition()) {
            if (!this.state.playForWin) {
              this.setState({ message: messages[this.state.language].congratulations, message2: messages[this.state.language].threefold })
            } else {
              this.setState({ message: messages[this.state.language].threefold })
            }
          } else if (this.chess.isInsufficientMaterial()) {
            if (!this.state.playForWin) {
              this.setState({ message: messages[this.state.language].congratulations, message2: messages[this.state.language].material })
            } else {
              this.setState({ message: messages[this.state.language].material })
            }
          } else if (this.chess.isDraw()) {
            if (!this.state.playForWin) {
              this.setState({ message: messages[this.state.language].congratulations, message2: messages[this.state.language].draw })
            } else {
              this.setState({ message: messages[this.state.language].draw })
            }
          }
        }
      }
    }
  }

  // keep clicked square style and remove hint squares
  public removeHighlightSquare = () => {
    this.setState(({ pieceSquare, history }) => ({
      squareStyles: squareStyling({ pieceSquare, history, matingSquare: this.state.matingSquare, stalemateSquare: this.state.stalemateSquare
      , squareColor: this.state.squareColor
    })
    }));
  };

  // show possible moves
  public highlightSquare = (sourceSquare, squaresToHighlight) => {
    const highlightStyles = [sourceSquare, ...squaresToHighlight].reduce(
      (a/*, c*/) => {
        return {
          ...a,
/*
          ...{
            [c]: {
              background:
                'radial-gradient(circle, #339966 36%, transparent 40%)', // #fffc00 color picker
              borderRadius: '50%'
            }
          },
*/
          ...squareStyling({
            history: this.state.history,
            pieceSquare: this.state.pieceSquare,
            matingSquare: this.state.matingSquare,
            stalemateSquare: this.state.stalemateSquare,
            squareColor: this.state.squareColor
          })
        };
      },
      {}
    );

    this.setState(({ squareStyles }) => ({
      squareStyles: { ...squareStyles, ...highlightStyles }
    }));
  };

  public calcMovable = () => {
    const dests = {}
    SQUARES.forEach(s => {
      const ms = this.chess.moves({ square: s, verbose: true })
      if (ms.length) dests[s] = ms.map(m => m.to)
    })
    return {
      free: false,
      dests,
      color: "white"
    }
  }

 public onDrop = ({ sourceSquare, targetSquare }) => {
    if (this.state.playAllowed) {
      const moves = this.chess.moves({ square: sourceSquare, verbose: true })
      let promotion = false
      for (const item of moves) {
        if (typeof item === 'string') {

        } else {
          if (item.promotion && item.to === targetSquare) {
            promotion = true
            break
          }
        }
      }
      if (promotion) {
        this.setState({ promotion: true, promotionSourceSquare: sourceSquare, promotionTargetSquare: targetSquare })
        return
      }

      const move = this.chess.move({
        from: sourceSquare,
        to: targetSquare
      });

      if (move === null) return;
      const fen: string = this.chess.fen()

      this.setState(({ history, pieceSquare }) => ({
        squareColor: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].square : [],
        arrows: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].arrow : [],
        squares: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].squares : [],
        activeDiagram: {which: -1, line: -1, item: -1, n: this.state.activeDiagram.n + 1},
        fen,
        history: this.chess.history({ verbose: true }),
        squareStyles: squareStyling({ pieceSquare, history, matingSquare: this.state.matingSquare, stalemateSquare: this.state.stalemateSquare
        , squareColor: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].square : []
        })
      }));

      if (this.state.firstMove && this.state.playForMate && (this.state.bestMoveFrom !== sourceSquare || this.state.bestMoveTo !== targetSquare)) {
        this.setState({ playAllowed: false, message: messages[this.state.language].better, message2: messages[this.state.language].again })
        this.send2(fen)
      } else if (this.chess.isCheckmate()) {
        const matingSquare: string = this.getMatingSquare(this.chess)
        if (this.state.playForWin && this.state.turn !== this.chess.turn()) {
          this.setState({ matingSquare, squareStyles: { [matingSquare]: { background: 'crimson' } }, message: messages[this.state.language].congratulations, message2: messages[this.state.language].mate })
        } else {
          this.setState({ matingSquare, squareStyles: { [matingSquare]: { background: 'crimson' } }, message: messages[this.state.language].mate })
        }
      } else if (this.chess.isStalemate()) {
        const stalemateSquare: string = this.getMatingSquare(this.chess, true)
        if (!this.state.playForWin) {
          this.setState({ stalemateSquare, squareStyles: { [stalemateSquare]: { background: 'coral' } }, message: messages[this.state.language].congratulations, message2: messages[this.state.language].stalemate })
        } else {
          this.setState({ stalemateSquare, squareStyles: { [stalemateSquare]: { background: 'coral' } }, message: messages[this.state.language].stalemate })
        }
      } else if (this.chess.isThreefoldRepetition()) {
        if (!this.state.playForWin) {
          this.setState({ message: messages[this.state.language].congratulations, message2: messages[this.state.language].threefold })
        } else {
          this.setState({ message: messages[this.state.language].threefold })
        }
      } else if (this.chess.isInsufficientMaterial()) {
        if (!this.state.playForWin) {
          this.setState({ message: messages[this.state.language].congratulations, message2: messages[this.state.language].material })
        } else {
          this.setState({ message: messages[this.state.language].material })
        }
      } else if (this.chess.isDraw()) {
        if (!this.state.playForWin) {
          this.setState({ message: messages[this.state.language].congratulations, message2: messages[this.state.language].draw })
        } else {
          this.setState({ message: messages[this.state.language].draw })
        }
      } else {
        if (!this.state.playForMate && this.state.variant) {
          const variantLeft: string = this.state.variant.substring(this.state.Variant.length, this.state.variant.length).trim()
          if (variantLeft.startsWith(sourceSquare + targetSquare)) {
            if (variantLeft === sourceSquare + targetSquare) {
              this.setState({ playAllowed: false, message: messages[this.state.language].congratulations, message2: messages[this.state.language].win })
            } else {
              const indexOf: number = variantLeft.indexOf(' ', 5)
              const nextMove: string = variantLeft.substring(5, indexOf)
              this.chess.move({
                from: nextMove.substring(0, 2),
                to: nextMove.substring(2, 4)
              });
              this.setState({firstMove: false, Variant: this.state.variant.substring(0, this.state.Variant.length + indexOf + 1)})
              this.send2(fen);
            }
          } else {
            this.setState({playAllowed: false, message: messages[this.state.language].better, message2: messages[this.state.language].again})
            this.send2(fen);    
          }
        } else {
          this.setState({firstMove: false})
          this.send2(fen);
        }
      }
    } else {
      // console.log('play is not allowed')
    }
  };

  public onMouseOverSquare = (square) => {
    // get list of possible moves for this square
    const moves = this.chess.moves({
      square: square,
      verbose: true
    });

    // exit if there are no moves available for this square
    if (moves.length === 0) {
      return;
    }

    const squaresToHighlight: string[] = [];
    for (const item of moves) {
      if (typeof item === 'string') {
      } else {
        squaresToHighlight.push(item.to);
      }
    }

    this.highlightSquare(square, squaresToHighlight);
  };

  public onMouseOutSquare = (/*square*/) => this.removeHighlightSquare(/*square*/);

  // central squares get diff dropSquareStyles
  public onDragOverSquare = (square) => {
    this.setState({
      dropSquareStyle:
        square === 'e4' || square === 'd4' || square === 'e5' || square === 'd5'
          ? { backgroundColor: 'cornFlowerBlue' }
          : { boxShadow: 'inset 0 0 1px 4px rgb(255, 255, 0)' }
    });
  };


  public onSquareClick = (square) => {
    if (this.state.playAllowed) {
      this.setState(({ history }) => ({
        squareStyles: squareStyling({ pieceSquare: square, history, matingSquare: this.state.matingSquare, stalemateSquare: this.state.stalemateSquare
        , squareColor: this.state.squareColor
        }),
        pieceSquare: square
      }));

      if (this.state.promotionCandidate) {
        let promotion = false
        for (const item of this.state.promotionSquares) {
          if (square === item) {
            promotion = true
            break
          }
        }
        if (promotion) {
          this.setState({ promotionCandidate: false, promotion, promotionTargetSquare: square, promotionSquares: [] })
          return
        }
      } else {
        const moves = this.chess.moves({ square, verbose: true })
        let promotionCandidate = false
        const promotionSquares: string[] = []
        for (const item of moves) {
          if (typeof item === 'string') {

          } else {
            if (item.promotion) {
              promotionCandidate = true
              let foundSquare = false
              for (const Item of promotionSquares) {
                if (Item === item.to) {
                  foundSquare = true
                  break
                }
              }
              if (!foundSquare) {
                promotionSquares.push(item.to)
              }
            }
          }
        }

        if (promotionCandidate) {
          this.setState({ promotionCandidate, promotionSquares, promotionSourceSquare: square })
          return
        }

      }

      const move = this.chess.move({
        from: this.state.pieceSquare,
        to: square
      });

      // illegal move
      if (move === null) return;
      const fen: string = this.chess.fen()

      this.setState({
        squareColor: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].square : [],
        arrows: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].arrow : [],
        squares: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].squares : [],
        squareStyles: squareStyling({ pieceSquare: '', history, matingSquare: this.state.matingSquare, stalemateSquare: this.state.stalemateSquare
        , squareColor: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].square : []
      }),
        activeDiagram: {which: -1, line: -1, item: -1, n: this.state.activeDiagram.n + 1},
        fen,
        history: this.chess.history({ verbose: true }),
        pieceSquare: ''
      });

      if (this.state.firstMove && this.state.playForMate && (this.state.bestMoveFrom !== this.state.pieceSquare || this.state.bestMoveTo !== square)) {
        this.setState({ playAllowed: false, message: messages[this.state.language].better, message2: messages[this.state.language].again })
        this.send2(fen)
      } else if (this.chess.isCheckmate()) {
        const matingSquare: string = this.getMatingSquare(this.chess)
        if (this.state.playForWin && this.state.turn !== this.chess.turn()) {
          this.setState({ matingSquare, squareStyles: { [matingSquare]: { background: 'crimson' } }, message: messages[this.state.language].congratulations, message2: messages[this.state.language].mate })
        } else {
          this.setState({ matingSquare, squareStyles: { [matingSquare]: { background: 'crimson' } }, message: messages[this.state.language].mate })
        }
      } else if (this.chess.isStalemate()) {
        const stalemateSquare: string = this.getMatingSquare(this.chess, true)
        if (!this.state.playForWin) {
          this.setState({ stalemateSquare, squareStyles: { [stalemateSquare]: { background: 'coral' } }, message: messages[this.state.language].congratulations, message2: messages[this.state.language].stalemate })
        } else {
          this.setState({ stalemateSquare, squareStyles: { [stalemateSquare]: { background: 'coral' } }, message: messages[this.state.language].stalemate })
        }
      } else if (this.chess.isThreefoldRepetition()) {
        if (!this.state.playForWin) {
          this.setState({ message: messages[this.state.language].congratulations, message2: messages[this.state.language].threefold })
        } else {
          this.setState({ message: messages[this.state.language].threefold })
        }
      } else if (this.chess.isInsufficientMaterial()) {
        if (!this.state.playForWin) {
          this.setState({ message: messages[this.state.language].congratulations, message2: messages[this.state.language].material })
        } else {
          this.setState({ message: messages[this.state.language].material })
        }
      } else if (this.chess.isDraw()) {
        if (!this.state.playForWin) {
          this.setState({ message: messages[this.state.language].congratulations, message2: messages[this.state.language].draw })
        } else {
          this.setState({ message: messages[this.state.language].draw })
        }
      } else {
        if (!this.state.playForMate && this.state.variant) {
          const variantLeft: string = this.state.variant.substring(this.state.Variant.length, this.state.variant.length).trim()
          if (variantLeft.startsWith(this.state.pieceSquare + square)) {
            if (variantLeft === this.state.pieceSquare + square) {
              this.setState({ playAllowed: false, message: messages[this.state.language].congratulations, message2: messages[this.state.language].win })
            } else {
              const indexOf: number = variantLeft.indexOf(' ', 5)
              const nextMove: string = variantLeft.substring(5, indexOf)
              this.chess.move({
                from: nextMove.substring(0, 2),
                to: nextMove.substring(2, 4)
              });
              this.setState({firstMove: false, Variant: this.state.variant.substring(0, this.state.Variant.length + indexOf + 1)})
              this.send2(fen);
            }
          } else {
            this.setState({playAllowed: false, message: messages[this.state.language].better, message2: messages[this.state.language].again})
            this.send2(fen);    
          }
        } else {
          this.setState({firstMove: false})
          this.send2(fen);
        }
      }
    }
  };

  public onSquareRightClick = (square) => {
    this.setState({
      squareStyles: { [square]: { backgroundColor: 'deepPink' } }
    });
  }

  public refresh = async () => {
    await this.backward(true)
    await this.forward()
  }

  public backward = async (refresh?: boolean) => {
    const getPosition = await dataServer.request('get', 'position', {
      pos: this.state.grouppos, uuid: this.state.prevuuid, customeruuid: this.state.customeruuid, groupuuid: this.state.groupuuid, backward: true,
      squares: [], arrows: [], squareColor: [], activeDiagram: {which: -1, line: -1, item: -1, n: -1}
    })
    this.getPosition(getPosition, refresh)
  }

  public Forward = async () => {
    const variantLeft: string = this.state.variant.substring(this.state.Variant.length, this.state.variant.length).trim()
    const indexOf: number = variantLeft.indexOf(' ')
    const nextMove: string = variantLeft.substring(0, indexOf !== -1 ? indexOf : variantLeft.length)
    if (nextMove.length >= 4) {
      const move = this.chess.move({
        from: nextMove.substring(0, 2),
        to: nextMove.substring(2, 4),
        promotion: nextMove.length === 5 ? nextMove.charAt(4).toLowerCase() : ''
      });
      if (move === null) return;
      const fen: string = this.chess.fen()
      const newVariant: string = this.state.variant.substring(0, indexOf !== -1 ? this.state.Variant.length + indexOf + 1 : this.state.variant.length)
      this.setState({
        fen,
        firstMove: false,
        Variant: newVariant,
        squareColor: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].square : [],
        arrows: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].arrow : [],
        squares: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].squares : [],
        activeDiagram: {which: -1, line: -1, item: -1, n: this.state.activeDiagram.n + 1},
      })
    }
  }

  public forward = async () => {
    const getPosition = await dataServer.request('get', 'position', {
      pos: this.state.grouppos, uuid: this.state.nextuuid, customeruuid: this.state.customeruuid, groupuuid: this.state.groupuuid, backward: false,
      squares: [], arrows: [], squareColor: [], activeDiagram: {which: -1, line: -1, item: -1, n: -1}
    })
    this.getPosition(getPosition)
  }

  public getPosition = (getPosition, refresh?: boolean) => {
    if (getPosition.data) {
      if (refresh) {
        const strategy: Strategy[] = this.getStrategy(0, getPosition.data.textenglong ? getPosition.data.textenglong : '-')
        this.setState({
          strategy,
          arrows: strategy.length ? strategy[0].arrow : [],
          squares: strategy.length ? strategy[0].squares : [],
          squareColor: strategy.length ? strategy[0].square : [],
          activeDiagram: {which: -1, line: -1, item: -1, n: 0},
          squareStyles: squareStyling({ pieceSquare: '', history: [], matingSquare: '', stalemateSquare: ''
          , squareColor: strategy.length ? strategy[0].square : []
        }),
          matingSquare: '',
          stalemateSquare: '',
          playAllowed: (getPosition.data.position ? getPosition.data.position : this.state.groupPosition) !== 3,
          getPosition,
          playForWin: getPosition.data.win,
          playForMate: getPosition.data.mate,
          firstMove: true,
          turn: refresh ? this.state.turn : this.chess.turn(),
          history: [],
          fen: getPosition.data.fen,
          groupuuid: getPosition.data.groupuuid,
          prevuuid: getPosition.data.prevuuid,
          uuid: getPosition.data.uuid,
          nextuuid: getPosition.data.nextuuid,
          variant: getPosition.data.variant,
          Variant: '',
          message: undefined,
          message2: undefined,
          groupname: getPosition.data.name ? getPosition.data.name : this.state.groupname,
          groupnamesl: getPosition.data.namesl ? getPosition.data.namesl : this.state.groupnamesl,
          grouppos: getPosition.data.pos ? getPosition.data.pos : this.state.grouppos,
          groupPosition: getPosition.data.position ? getPosition.data.position : this.state.groupPosition
        })
      } else {
        this.chess.load(getPosition.data.fen)

        if (getPosition.data.mate) {
          this.Send('position fen ' + getPosition.data.fen);
          this.Send('go depth 10')
        }
        const strategy: Strategy[] = this.getStrategy(0, getPosition.data.textenglong ? getPosition.data.textenglong : '-')
        this.setState({
          strategy,
          arrows: strategy.length ? strategy[0].arrow : [],
          squares: strategy.length ? strategy[0].squares : [],
          squareColor: strategy.length ? strategy[0].square : [],
          activeDiagram: {which: -1, line: -1, item: -1, n: 0},
          squareStyles: squareStyling({ pieceSquare: '', history: [], matingSquare: '', stalemateSquare: ''
          , squareColor: strategy.length ? strategy[0].square : []
        }),

          matingSquare: '',
          stalemateSquare: '',
          playAllowed: (getPosition.data.position ? getPosition.data.position : this.state.groupPosition) !== 3,
          getPosition,
          playForWin: getPosition.data.win,
          playForMate: getPosition.data.mate,
          firstMove: true,
          turn: refresh ? this.state.turn : this.chess.turn(),
          history: [],
          fen: getPosition.data.fen,
          groupuuid: getPosition.data.groupuuid,
          prevuuid: getPosition.data.prevuuid,
          uuid: getPosition.data.uuid,
          nextuuid: getPosition.data.nextuuid,
          variant: getPosition.data.variant,
          Variant: '',
          message: undefined,
          message2: undefined,
          groupname: getPosition.data.name ? getPosition.data.name : this.state.groupname,
          groupnamesl: getPosition.data.namesl ? getPosition.data.namesl : this.state.groupnamesl,
          grouppos: getPosition.data.pos ? getPosition.data.pos : this.state.grouppos,
          groupPosition: getPosition.data.position ? getPosition.data.position : this.state.groupPosition
        })
      }
    }
  }

  public onLogin = async (event: FormEvent) => {
    event.preventDefault();
    const w = screen.width
    if (this.state.username && this.state.password) {
      const getAuthorization = await dataServer.request('post', 'login', {
        username: this.state.username,
        password: this.state.password,
      })
      if (getAuthorization.data && getAuthorization.data.data && getAuthorization.data.resolved && getAuthorization.data.message === 'authorization successful') {
        const getPosition = await dataServer.request('get', 'position', {
          pos: this.state.grouppos, uuid: getAuthorization.data.data.positionuuid, customeruuid: getAuthorization.data.data.uuid, groupuuid: null
        })
        if (getPosition.data && getPosition.data.fen) {
          this.chess.load(getPosition.data.fen)
          if (getPosition.data.mate) {
            this.Send('position fen ' + getPosition.data.fen);
            this.Send('go depth 10')
          }
          const strategy: Strategy[] = this.getStrategy(0, getPosition.data.textenglong ? getPosition.data.textenglong : '-')
          this.setState({
            strategy,
            arrows: strategy.length ? strategy[0].arrow : [],
            squares: strategy.length ? strategy[0].squares : [],
            squareColor: strategy.length ? strategy[0].square : [],
            activeDiagram: {which: -1, line: -1, item: -1, n: 0},
            squareStyles: squareStyling({ pieceSquare: '', history: [], matingSquare: '', stalemateSquare: ''
            , squareColor: strategy.length ? strategy[0].square : []
          }),
            matingSquare: '',
            stalemateSquare: '',
            playAllowed: (getPosition.data.position ? getPosition.data.position : this.state.groupPosition) !== 3,
            getPosition,
            history: [],
            turn: this.chess.turn(),
            playForMate: getPosition.data.mate,
            firstMove: true,
            playForWin: getPosition.data.win,
            // squareStyles: squareStyling({ pieceSquare: this.state.pieceSquare, history }),
            admin: w > 1024 ? getAuthorization.data.data.admin : false,
            Admin: w > 1024 ? getAuthorization.data.data.admin : false,
            user: getAuthorization.data.data.username,
            customeruuid: getAuthorization.data.data.uuid,
            positionuuid: getAuthorization.data.data.positionuuid,
            fen: getPosition.data.fen,
            groupuuid: getPosition.data.groupuuid,
            prevuuid: getPosition.data.prevuuid,
            uuid: getPosition.data.uuid,
            nextuuid: getPosition.data.nextuuid,
            variant: getPosition.data.variant,
            Variant: '',
            message: undefined,
            message2: undefined,
            groupname: getPosition.data.name ? getPosition.data.name : this.state.groupname,
            groupnamesl: getPosition.data.namesl ? getPosition.data.namesl : this.state.groupnamesl,
            grouppos: getPosition.data.pos ? getPosition.data.pos : this.state.grouppos,
            groupPosition: getPosition.data.position ? getPosition.data.position : this.state.groupPosition
          })
        }
      }
    }
  }

  public onRegister = async (event: FormEvent) => {
    event.preventDefault();
    const w = screen.width
    if (this.state.newusername && this.state.password1 && this.state.password2 && this.state.email) {
      const getAuthorization = await dataServer.request('post', 'register', {
        username: this.state.newusername,
        password1: this.state.password1,
        password2: this.state.password2,
        email: this.state.email
      })
      if (getAuthorization.data && getAuthorization.data.data && getAuthorization.data.resolved && getAuthorization.data.message === 'authorization successful') {
        const getPosition = await dataServer.request('get', 'position', {
          pos: this.state.grouppos, uuid: getAuthorization.data.data.positionuuid, customeruuid: getAuthorization.data.data.uuid, groupuuid: null
        })

        if (getPosition.data) {
          this.chess.load(getPosition.data.fen)
          if (getPosition.data.mate) {
            this.Send('position fen ' + getPosition.data.fen);
            this.Send('go depth 10')
          }
          const strategy: Strategy[] = this.getStrategy(0, getPosition.data.textenglong ? getPosition.data.textenglong : '-')
          
          this.setState({
            strategy,
            arrows: strategy.length ? strategy[0].arrow : [],
            squares: strategy.length ? strategy[0].squares : [],
            squareColor: strategy.length ? strategy[0].square : [],
            activeDiagram: {which: -1, line: -1, item: -1, n: 0},
            squareStyles: squareStyling({ pieceSquare: '', history: [], matingSquare: '', stalemateSquare: ''
            , squareColor: strategy.length ? strategy[0].square : []
          }),
            matingSquare: '',
            stalemateSquare: '',
            playAllowed: (getPosition.data.position ? getPosition.data.position : this.state.groupPosition) !== 3,
            getPosition,
            turn: this.chess.turn(),
            playForWin: getPosition.data.win,
            playForMate: getPosition.data.mate,
            firstMove: true,
            history: [],
            // squareStyles: squareStyling({ pieceSquare: this.state.pieceSquare, history }),
            admin: w > 1024 ? getAuthorization.data.data.admin : false,
            Admin: w > 1024 ? getAuthorization.data.data.admin : false,
            user: getAuthorization.data.data.username,
            customeruuid: getAuthorization.data.data.uuid,
            positionuuid: getAuthorization.data.data.positionuuid,
            fen: getPosition.data.fen,
            groupuuid: getPosition.data.groupuuid,
            prevuuid: getPosition.data.prevuuid,
            uuid: getPosition.data.uuid,
            nextuuid: getPosition.data.nextuuid,
            variant: getPosition.data.variant,
            Variant: '',
            message: undefined,
            message2: undefined,
            groupname: getPosition.data.name ? getPosition.data.name : this.state.groupname,
            groupnamesl: getPosition.data.namesl ? getPosition.data.namesl : this.state.groupnamesl,
            grouppos: getPosition.data.pos ? getPosition.data.pos : this.state.grouppos,
            groupPosition: getPosition.data.position ? getPosition.data.position : this.state.groupPosition
          })
        }
      }
    }
  }

  public setDataValue = (field: 'password1' | 'password2' | 'email' | 'password' | 'username' | 'newusername', value: string) => {
    const state: State = Object.assign({}, this.state)
    state[field] = value
    this.setState(state)
  }

  public async gotoAdminMode() {
    const customer = await dataServer.request('get', 'customer', {})
    if (customer.data) {
      const groups = await dataServer.request('get', 'group', {})
      if (groups.data) {
        const group: Group[] = []
        let uuid: string | null = null
        for (const { } of groups.data) {
          for (const Item of groups.data) {
            if (Item.prevuuid === uuid) {
              group.push(Item)
              uuid = Item.uuid
              break
            }
          }
        }
        for (let i = 0; i < customer.data.length; i++) {
          for (const item of group) {
            if (customer.data[i].groupuuid === item.uuid) {
              customer.data[i].group = item.name
            }
          }
        }
        this.setState({ admin: true, customer: customer.data, group })
      }
    }
  }

  public toggleCustomerAdmin(uuid: string) {
    dataServer.request('put', 'toggle-customer', { uuid })
    const customer: Customer[] = Object.assign([], this.state.customer)
    for (let i = 0; i < customer.length; i++) {
      if (customer[i].uuid === uuid) {
        customer[i].admin = !customer[i].admin
        break
      }
    }
    this.setState({ customer })
  }

  public editTextarea(what: 'textengshort'|'textenglong'|'textslshort', value: string) {
    if (what === 'textengshort') {
      this.setState({textengshort: value})
    } else if (what === 'textenglong') {
      this.setState({textenglong: value})
    } else if (what === 'textslshort') {
      this.setState({textslshort: value})
    }
  }

  public async saveChanges() {
    await dataServer.request('post', 'set-text', {
      uuid: this.state.position[0].uuid,
      textengshort: this.state.textengshort,
      textenglong: this.state.textenglong,
      textslshort: this.state.textslshort,
    })
  }

  public async selectGroup(groupsuuid: string) {
    let groupIndex = -1
    for (let i = 0; i < this.state.group.length; i++) {
      if (this.state.group[i].uuid === groupsuuid) {
        groupIndex = i
        break
      }
    }

    const positions = await dataServer.request('get', 'positionposition', { groupsuuid, positionposition: this.state.positionposition })
    for (const item of positions.data) {
      item.move = []
      if (this.state.move) {
        for (const Item of this.state.move) {
          if (Item.positionuuid === item.uuid) {
            item.move.push(Item)
          }
        }
      }
    }
    this.setState({textenglong: positions.data[0] && positions.data[0].textenglong ? positions.data[0].textenglong : '', 
      textengshort: positions.data[0] && positions.data[0].textengshort ? positions.data[0].textengshort : '', 
      textslshort: positions.data[0] && positions.data[0].textslshort ? positions.data[0].textslshort : '', 
      
      pNum: 0, groupIndex, groupsuuid, position: positions.data, newFen: '', newWin: true, newMate: true, newVariant: '', positionposition: 1 })
  }

  public insertMove(index: number, positionuuid: string) {
    const id: number = this.state.position[index].move ? this.state.position[index].move.length + 1 : 1
    dataServer.request('post', 'insert-move', {
      id,
      color: 'w',
      positionuuid,
    })

    const move: Move[] = Object.assign([], this.state.move)
    const position: Position[] = Object.assign([], this.state.position)
    move.push({
      id,
      fen: '',
      source: '',
      target: '',
      piece: '',
      color: 'w',
      positionuuid
    })
    if (position[index].move === undefined) { position[index].move = [] }
    position[index].move.push({
      id,
      fen: '',
      source: '',
      target: '',
      piece: '',
      color: 'w',
      positionuuid
    })

    this.setState({ move, position })
  }

  public showForcedLine(fen: string, variant: string): string {
    let moves: string[] = []
    if (fen && fen.length && variant && variant.length) {
      const chess = new Chess(fen)
      moves = variant.split(' ')
      for (const item of moves) {
        if (item.length === 4 || item.length === 5) {
          chess.move({from: item.substring(0,2), to: item.substring(2,4), promotion: item.length === 5 ? item.substring(4,5).toLowerCase() : 'q'})
        }  
      }
      let returned: string = chess.pgn()
      returned = returned.substring(returned.indexOf('"]', 10) + '"]'.length, returned.length).trim()

      let Returned = ''
      for (let i = 0; i < returned.length; i++) {
        let piece: PIECE = PIECE.PAWN
        if (returned[i] === 'K') {
          piece = PIECE.KING
        }  else if (returned[i] === 'Q') {
          piece = PIECE.QUEEN
        }  else if (returned[i] === 'R') {
          piece = PIECE.ROOK
        }  else if (returned[i] === 'B') {
          piece = PIECE.BISHOP
        }  else if (returned[i] === 'N') {
          piece = PIECE.KNIGHT
        }
        if (piece !== PIECE.PAWN) {
          Returned += whitePieces[piece]
        } else {
          Returned += returned.charAt(i)
        }
      }
      return Returned
    }
    return '-'
  }

  public getElement(index: number, what: 'fen' | 'source' | 'target' | 'piece' | 'color', which: number): string {
    return this.state.position[index].move && this.state.position[index].move[which - 1] ? this.state.position[index].move[which - 1][what] : ''
  }

  public moveExists(index: number, which: number): boolean {
    return this.state.position[index] && this.state.position[index].move && this.state.position[index].move[which - 1] ? true : false
  }

  public moveExistsPiece(index: number, which: number): boolean {
    return this.state.position[index] && this.state.position[index].move && this.state.position[index].move[which - 1] && this.state.position[index].move[which - 1].piece.length ? true : false
  }

  public editElement(index: number, what: 'fen' | 'source' | 'target' | 'piece' | 'color' | 'variant', value: any, which?: number) {
    if (which) {
      if ((what === 'source' || what === 'target') && value.length > 2) {return}
      if (this.state.position[index].move[which - 1]) {
        const position: Position[] = Object.assign([], this.state.position)
        dataServer.request('put', 'update-move', {
          id: which,
          positionuuid: position[index].move[which - 1].positionuuid,
          what,
          value
        })
        if (what === 'piece') {
          if (value === 'p') {
            value = 'n'
          } else if (value === 'n') {
            value = 'b'
          } else if (value === 'b') {
            value = 'r'
          } else if (value === 'r') {
            value = 'q'
          } else if (value === 'q') {
            value = 'k'
          } else if (value === 'k') {
            value = ''
          } else if (value === '') {
            value = 'p'
          }
        } else if (what === 'color') {
          if (value === 'w') {
            value = 'b'
          } else if (value === 'b') {
            value = 'w'
          }
        }
        position[index].move[which - 1][what] = value

        this.setState({ position })
      }
    } else {
      const position: Position[] = Object.assign([], this.state.position)
      position[index][what] = value
      dataServer.request('put', 'update-position', {
        fen: position[index].fen,
        uuid: position[index].uuid,
        win: position[index].win,
        mate: position[index].mate,
        variant: position[index].variant
      })
      this.setState({ position })
    }
  }

  public validate(fen: string, source: Square, target: Square, color: Color, piece?: PieceSymbol): boolean {
    if (!fen || !source || !target || !color) {return false}
    if (!fen.length || !source.length || !target.length || !color.length) {return false}
    if (!validateFEN(fen)) {return false}
    const chess = new Chess(fen)
    const square1 = chess.get(source)
    if (square1.color !== color && (!piece && piece && square1.type !== piece )) {
      return false
    }
    const square2 = chess.get(target)
    if (square2 !== null) {return false}
    return true
  }

  public getMoves(): JSX.Element[] {
    const moves: JSX.Element[] = []
    if (this.state.variant && this.state.variant.length) {
      const Moves: string[] = this.state.variant.split(' ')
      const chess: Chess = new Chess(this.state.Fen)
      let color: 'b'|'w' = chess.turn()
      let n = 1
      for(const item of Moves) {
        if (item.length === 4 || item.length === 5) {
            chess.move({from: item.substring(0,2), to: item.substring(2,4), promotion: item.length === 5 ? item.substring(4,5).toLowerCase() : 'q'})
          moves.push(<div title={item} style={{ position: 'relative', float: 'left', marginTop: '10px', marginLeft: Moves.length < 11 ? '30px' : '33px', textAlign: 'center'}} key={n}>
            <span style = {{position: 'absolute', top: '0px', left: Moves.length < 11 ? '-22px' : '-25px'}}>{n}.</span>
            <Chessground style = {{cursor: 'default'}} lastMove = {[item.substring(2,4), item.substring(0,2)]} width={Moves.length < 4 ? 424 : Moves.length < 11 ? 240 : 160} height={Moves.length < 4 ? 424 : Moves.length < 11 ? 240 : 160} orientation={this.state.orientation === 'b' ? 'black' : 'white'} viewOnly={true} coordinates={false} fen={chess.fen()}/>
          </div>
          )
          n++
          if (color === 'b') {
            color = 'w'
          } else {
            color = 'b'
          }
        } 
      }
    } else if (this.state.positionIndex !== -1 && this.state.position && this.state.position[this.state.positionIndex] && this.state.position[this.state.positionIndex].move) {
      for (const item of this.state.position[this.state.positionIndex].move) {
        const validate: boolean = this.validate(item.fen, item.source as Square, item.target as Square, item.color as Color, item.piece as PieceSymbol)
        moves.push(<div style={{ float: 'left', marginLeft: '10px', textAlign: 'center'}} key={item.id}>
          <div style = {{marginTop: '5px', fontSize: '20px', marginBottom: '5px', color: validate ? '' : 'red'}}>{validate ? 'overridden engine move is valid' : 'overridden engine move IS NOT valid' }</div>
          {validate ? 
            <Chessground style = {{cursor: 'default'}} lastMove = {[item.target, item.source]} width={328} height={328} orientation={this.state.orientation === 'b' ? 'black' : 'white'} viewOnly={true} coordinates={false} fen={item.fen}/>
          : null}
          <div style = {{marginTop: '5px', fontSize: '20px'}}><img height = {20} width = {20} src = {this.getPIECE(item.color, item.piece)}/>{item.source}-{item.target}</div>
        </div>
        )
      }
    }

    return moves
  }

  public showChessboard(variant: string|null, Fen: string, positionUuid: string, positionIndex: number) {
    if (positionUuid === this.state.positionUuid) {
      this.setState({ background: false, Fen: undefined, positionUuid: undefined, positionIndex: -1 })
    } else {
      let background = false
      if (this.state.move) {
        for (const item of this.state.move) {
          if (item.positionuuid === positionUuid) {
            background = true
            break
          }
        }
      }
      if (!background && variant && variant.length) {
        background = true
      }

      this.setState({ variant: variant ? variant: '', background, orientation: (new Chess(Fen)).turn(), Fen, positionUuid, positionIndex })
    }
  }

  public getColors(): JSX.Element[] {
    const c: JSX.Element[] = []
    for (let i = 0; i < colors.length; i++) {
      c.push(<div key = {i.toString()} style = {{background: this.getStrategyColor(colors[i]), color: '#fff', width: 100/colors.length + '%',float: 'left', height: '100%', textAlign: 'center'}}>{colors[i]}</div>)
    }
    return c
  }

  public editElementBoolean(index: number, what: 'mate' | 'win', value: boolean) {
    const position: Position[] = Object.assign([], this.state.position)
    position[index][what] = value
    dataServer.request('put', 'update-position', {
      fen: position[index].fen,
      uuid: position[index].uuid,
      win: position[index].win,
      mate: position[index].mate,
      variant: position[index].variant
    })
    this.setState({ position })
  }

  public async insertNewFen() {
    const newFen = await dataServer.request('post', 'insert-position', {
      fen: this.state.newFen,
      win: this.state.newWin,
      mate: this.state.newMate,
      groupuuid: this.state.groupsuuid,
    })
    if (newFen && newFen.data && newFen.data.uuid) {
      const group: Group[] = Object.assign([], this.state.group)
      group[this.state.groupIndex].count = +group[this.state.groupIndex].count + 1
      this.setState({ newFen: '', textenglong: '', textengshort: '', textslshort: '', position: [
        {
          uuid: newFen.data.uuid,
          prevuuid: newFen.data.prevuuid,
          nextuuid: null,
          fen: this.state.newFen,
          variant: this.state.newVariant,
          win: this.state.newWin,
          mate: this.state.newMate,
          move: [],
          textenglong: '',
          textengshort: '',
          textslshort: '',
        }

      ], group, positionposition: group[this.state.groupIndex].count, newVariant: '', background: false, Fen: undefined, positionUuid: undefined })
    }
  }

  public setGroupName(what: 'name' | 'namesl', value: string) {
    const group: Group[] = Object.assign([], this.state.group)
    group[this.state.groupIndex][what] = value
    dataServer.request('put', 'update-group', {
      uuid: group[this.state.groupIndex].uuid,
      name: group[this.state.groupIndex].name,
      namesl: group[this.state.groupIndex].namesl,
      position: group[this.state.groupIndex].position,
    })
    this.setState({ group })
  }

  public setGroupPosition(what: 'position', value: number) {
    const group: Group[] = Object.assign([], this.state.group)
    group[this.state.groupIndex][what] = value
    dataServer.request('put', 'update-group', {
      uuid: group[this.state.groupIndex].uuid,
      name: group[this.state.groupIndex].name,
      namesl: group[this.state.groupIndex].namesl,
      position: group[this.state.groupIndex].position,
    })
    this.setState({ group })
  }

  public getPositions(): JSX.Element[] {
    const p: JSX.Element[] = []
    for (let i: number = this.state.pNum * 10; i < this.state.position.length && i < (this.state.pNum + 1) * 10; i++) {
      p.push(<div className={this.state.position[i].move && this.state.position[i].move.length || this.state.position[i].variant && this.state.position[i].variant.length ? 'cgwrap clearfix' : 'clearfix'} onClick={() => this.showChessboard(this.state.position[i].variant, this.state.position[i].fen, this.state.position[i].uuid, i)} key={i} style={{ cursor: 'pointer', width: '20%', float: 'left', marginTop: '5px' }}>
        <Chessground style={{ float: 'left', height: 256 + 512, width: 256 + 512}} orientation={this.state.position[i].fen.indexOf(' b ') !== -1 ? 'black' : 'white'} viewOnly={true} coordinates={false} fen={this.state.position[i].fen} />
      </div>)
    }
    return p
  }

  public deleteMove(index: number, positionuuid: string, id: number) {
    if (confirm('Do you really want to delete the override engine move #' + id + '?')) {
      dataServer.request('delete', 'delete-move', { positionuuid, id })
      const position: Position[] = Object.assign([], this.state.position)
      const move: Move[] = Object.assign([], this.state.move)
      for (let i = 0; i < move.length; i++) {
        if (move[i].positionuuid === positionuuid && move[i].id === id) {
          move.splice(i, 1)
          break
        }
      }
      if (position[index].move && position[index].move[id - 1]) {
        position[index].move.splice(id - 1, 1)
      }
      this.setState({ position, move })
    }
  }

  public deletePosition(index: number, uuid: string, prevuuid: string, nextuuid: string) {
    if (confirm('Do you really want to delete the position ' + (this.state.positionposition) + '?')) {
      if (prevuuid !== null || nextuuid !== null) {
        const position: Position[] = Object.assign([], this.state.position)
        if (prevuuid !== null) {
          for (let i = 0; i < position.length; i++) {
            if (position[i].uuid === prevuuid) {
              position[i].nextuuid = nextuuid
            }
          }
        }
        if (nextuuid !== null) {
          for (let i = 0; i < position.length; i++) {
            if (position[i].uuid === nextuuid) {
              position[i].prevuuid = prevuuid
            }
          }
        }
        position.splice(index, 1)
        const group: Group[] = Object.assign([], this.state.group)
        group[this.state.groupIndex].count = +group[this.state.groupIndex].count - 1

        dataServer.request('delete', 'delete-position', { uuid, prevuuid, nextuuid })
        this.setState({ position, group })
      } else {
        alert('Cannot delete the last element!')
      }
    }
  }
  
  public promote(piece: 'q' | 'r' | 'b' | 'n') {
    const move = this.chess.move({
      from: this.state.promotionSourceSquare || '',
      to: this.state.promotionTargetSquare || '',
      promotion: piece
    });

    // illegal move
    if (move === null) return;

    const fen: string = this.chess.fen()

    this.setState(({ history, pieceSquare }) => ({
      squareColor: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].square : [],
      arrows: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].arrow : [],
      squares: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].squares : [],
      activeDiagram: {which: -1, line: -1, item: -1, n: this.state.activeDiagram.n + 1},
      fen,
      history: this.chess.history({ verbose: true }),
      squareStyles: squareStyling({ pieceSquare, history, matingSquare: this.getMatingSquare(this.chess), stalemateSquare: this.getMatingSquare(this.chess, true)
      , squareColor: this.state.strategy.length > this.state.activeDiagram.n ? this.state.strategy[this.state.activeDiagram.n + 1].square : []
      })
    }));

    if (this.state.firstMove && this.state.playForMate && (this.state.bestMoveFrom !== this.state.promotionSourceSquare || this.state.bestMoveTo !== this.state.promotionTargetSquare)) {
      this.setState({ promotion: false, promotionSourceSquare: undefined, promotionTargetSquare: undefined, playAllowed: false, message: messages[this.state.language].better, message2: messages[this.state.language].again })
      this.send2(fen);
    } else if (this.chess.isCheckmate()) {
      const matingSquare: string = this.getMatingSquare(this.chess)
      if (this.state.playForWin && this.state.turn !== this.chess.turn()) {
        this.setState({ matingSquare, squareStyles: { [matingSquare]: { background: 'crimson' } }, promotion: false, promotionSourceSquare: undefined, promotionTargetSquare: undefined, message: messages[this.state.language].congratulations, message2: messages[this.state.language].mate })
      } else {
        this.setState({ matingSquare, squareStyles: { [matingSquare]: { background: 'crimson' } }, promotion: false, promotionSourceSquare: undefined, promotionTargetSquare: undefined, message: messages[this.state.language].mate })
      }

    } else if (this.chess.isStalemate()) {
      const stalemateSquare: string = this.getMatingSquare(this.chess, true)
      if (!this.state.playForWin) {
        this.setState({ stalemateSquare, squareStyles: { [stalemateSquare]: { background: 'coral' } }, promotion: false, promotionSourceSquare: undefined, promotionTargetSquare: undefined, message: messages[this.state.language].congratulations, message2: messages[this.state.language].stalemate })
      } else {
        this.setState({ stalemateSquare, squareStyles: { [stalemateSquare]: { background: 'coral' } }, promotion: false, promotionSourceSquare: undefined, promotionTargetSquare: undefined, message: messages[this.state.language].stalemate })
      }

    } else if (this.chess.isThreefoldRepetition()) {
      if (!this.state.playForWin) {
        this.setState({ promotion: false, promotionSourceSquare: undefined, promotionTargetSquare: undefined, message: messages[this.state.language].congratulations, message2: messages[this.state.language].threefold })
      } else {
        this.setState({ promotion: false, promotionSourceSquare: undefined, promotionTargetSquare: undefined, message: messages[this.state.language].threefold })
      }

    } else if (this.chess.isInsufficientMaterial()) {
      if (!this.state.playForWin) {
        this.setState({ promotion: false, promotionSourceSquare: undefined, promotionTargetSquare: undefined, message: messages[this.state.language].congratulations, message2: messages[this.state.language].material })
      } else {
        this.setState({ promotion: false, promotionSourceSquare: undefined, promotionTargetSquare: undefined, message: messages[this.state.language].material })
      }

    } else if (this.chess.isDraw()) {
      if (!this.state.playForWin) {
        this.setState({ promotion: false, promotionSourceSquare: undefined, promotionTargetSquare: undefined, message: messages[this.state.language].congratulations, message2: messages[this.state.language].draw })
      } else {
        this.setState({ promotion: false, promotionSourceSquare: undefined, promotionTargetSquare: undefined, message: messages[this.state.language].draw })
      }
    } else {
      if (!this.state.playForMate && this.state.variant && this.state.promotionSourceSquare && this.state.promotionTargetSquare) {
        const variantLeft: string = this.state.variant.substring(this.state.Variant.length, this.state.variant.length).trim()
        if (variantLeft.startsWith(this.state.promotionSourceSquare + this.state.promotionTargetSquare + piece.toLowerCase())) {
          if (variantLeft === this.state.promotionSourceSquare + this.state.promotionTargetSquare + piece.toLowerCase()) {
            this.setState({ promotion: false, playAllowed: false, message: messages[this.state.language].congratulations, message2: messages[this.state.language].win })
          } else {
            const indexOf: number = variantLeft.indexOf(' ', 5)
            const nextMove: string = variantLeft.substring(5, indexOf)
            this.chess.move({
              from: nextMove.substring(0, 2),
              to: nextMove.substring(2, 4)
            });
            this.setState({firstMove: false, Variant: this.state.variant.substring(0, this.state.Variant.length + indexOf + 1)})
            this.send2(fen);
          }
        } else {
          this.setState({playAllowed: false, message: messages[this.state.language].better, message2: messages[this.state.language].again})
          this.send2(fen);    
        }
      
      } else {
        this.setState({ firstMove: false, promotion: false })
        this.send2(fen);
      }
    }
  }

  public getPIECE(color: ColorType, piece: PieceType) {
    if (color === 'w') {
      if (piece === 'p') {
        return pawn
      } else if (piece === 'n') {
        return knight
      } else if (piece === 'b') {
        return bishop
      } else if (piece === 'r') {
        return rook
      } else if (piece === 'q') {
        return queen
      } else if (piece === 'k') {
        return king
      }
    } else {
      if (piece === 'p') {
        return Pawn
      } else if (piece === 'n') {
        return Knight
      } else if (piece === 'b') {
        return Bishop
      } else if (piece === 'r') {
        return Rook
      } else if (piece === 'q') {
        return Queen
      } else if (piece === 'k') {
        return King
      }
    }
    return null//questionMark
  }

  public async setMove() {
    const move: Move[] = (await dataServer.request('get', 'move', {})).data
    this.setState({ move })
  }

  public setPosition(uuid: string) {
    if (uuid) {
      dataServer.request('post', 'setposition', {uuid, customeruuid: this.state.customeruuid})
    }
  }

  public async setPosition2(uuid: string|null, next: boolean) {
    const positions = await dataServer.request('get', 'position2', { groupsuuid: this.state.groupsuuid, uuid })
    for (const item of positions.data) {
      item.move = []
      if (this.state.move) {
        for (const Item of this.state.move) {
          if (Item.positionuuid === item.uuid) {
            item.move.push(Item)
          }
        }
      }
    }

    this.setState({positionposition: this.state.positionposition + (next ? 1 : -1), position: positions.data, background: false, Fen: undefined, positionUuid: undefined})
  }

  public async setPosition0(next: boolean) {
    const positions = await dataServer.request('get', 'position0', { groupsuuid: this.state.groupsuuid, next })
    for (const item of positions.data) {
      item.move = []
      if (this.state.move) {
        for (const Item of this.state.move) {
          if (Item.positionuuid === item.uuid) {
            item.move.push(Item)
          }
        }
      }
    }

    this.setState({positionposition: next ? this.getCountPositionsInGroup() : 1, position: positions.data, background: false, Fen: undefined, positionUuid: undefined})
  }

  public async setPositionPosition(positionposition: number) {
    const positions = await dataServer.request('get', 'positionposition', { groupsuuid: this.state.groupsuuid, positionposition })
    for (const item of positions.data) {
      item.move = []
      if (this.state.move) {
        for (const Item of this.state.move) {
          if (Item.positionuuid === item.uuid) {
            item.move.push(Item)
          }
        }
      }
    }

    this.setState({textenglong: positions.data[0] && positions.data[0].textenglong ? positions.data[0].textenglong : '', 
    textengshort: positions.data[0] && positions.data[0].textengshort ? positions.data[0].textengshort : '', 
    textslshort: positions.data[0] && positions.data[0].textslshort ? positions.data[0].textslshort : '', 
      
      positionposition, position: positions.data, background: false, Fen: undefined, positionUuid: undefined})
  }

  public getPositionOptions(): JSX.Element[] {
    const po: JSX.Element[] = []
    for (const item of this.state.group) {
      if (item.uuid === this.state.groupsuuid) {
        for (let i = 1; i <= item.count; i++) {
          po.push(<option key = {i} value = {i}>{i}</option>)
        }
        break
      }
    }
    return po
  }

  public getCountPositionsInGroup(): number {
    return this.state.group && this.state.group[this.state.groupIndex] ? +this.state.group[this.state.groupIndex].count : 0
  }

  // https://www.w3schools.com/colors/colors_names.asp
  
  public getStrategyColor(c: ColorAbbr): string {
    switch (c) {
      case 'i': return 'aqua';
      case 'j': return 'aquamarine';
      case 'k': return 'blue';
      case 'l': return 'blueviolet';
      case 'm': return 'brown';
      case 'n': return 'teal';
      case 'o': return 'chocolate';
      case 'p': return 'cornflowerblue';
      case 'q': return 'crimson';
      case 'r': return 'darkblue';
      case 's': return 'darkgreen';
      case 't': return 'darkmagenta';
      case 'u': return 'darkred';
      case 'v': return 'deeppink';
      case 'w': return 'dodgerblue';
      case 'x': return 'firebrick';
      case 'y': return 'forestgreen';
      case 'z': return 'gold';
      case '0': return 'goldenrod';
      case '1': return 'gray';
      case '2': return 'green';
      case '3': return 'indianred';
      case '4': return 'indigo';
      case '5': return 'lawngreen';
      case '6': return 'royalblue';
      case '7': return 'sienna';
      case '8': return 'turquoise';
      case '9': return 'yellow';
      default: return 'transparent';
    }
  }

  public setActiveDiagram(which: number, line: number, n: number, N: number, strategy: Strategy) {
    let squareColor: StrategyColor[] = []
    if (strategy && strategy.square) {
      squareColor = strategy.square
    }

    let arrows: StrategyArrow[] = []
    if (strategy && strategy.arrow) {
      arrows = strategy.arrow
    }
    let squares: StrategyColor[] = []
    if (strategy && strategy.squares) {
      squares = strategy.squares
    }
    this.setState({arrows, squares, squareStyles: squareStyling({ pieceSquare: '', history: this.state.history, matingSquare: '', stalemateSquare: '', squareColor}), squareColor, activeDiagram: {which, line, item: n, n: N}})
  }

  public isActiveDiagram(N: number): boolean {
    return this.state.activeDiagram ? this.state.activeDiagram.n === N : false
  }
  

  public getStrategy(which: number, strategy: string): Strategy[] {
    const s: string[] = strategy.split('|')
    const r: Strategy[] = []
    if (s) {
      for (let i = 0; i < s.length; i++) {
        const l: {n: number, l: Strategy[]} = this.getStrategyLines(s[i], i, which)
        r.push(...l.l)
      }
    }
    return r
  }

  public getStrategyLines(line: string, Line: number, which: number): {n: number, l: Strategy[]} {
    const l: Strategy[] = []
    let position = 0
    let nn = 0
    for (let n = 0; ; n++) {
      if (position >= line.length) {nn = n; break}
      const a = line.indexOf('{', position)
      const b = line.indexOf('[', position)
      const c = line.indexOf('<', position)
      if (a === -1 && b === -1 && c === -1) {
        nn = n; 
        break
      } else if (a !== -1 && (b === -1 && c === -1 || b !== -1 && c === -1 && a < b || c !== -1 && b === -1 && a < c || b !== -1 && c !== -1 && a < b && a < c)) {
        if (a > 0) {
          position = a
        }
        const position2: number = line.indexOf('}', position) + 1
        const strategy: Strategy = {which, line: Line, item: n, square: [], squares: [], arrow: []}
        const A: string[] = line.substring(position + 1, position2 - 1).split(',')
        for (const item of A) {
          if (item.charCodeAt(1) >= 'A'.charCodeAt(0) && item.charCodeAt(1) <= 'H'.charCodeAt(0)) {
            strategy.squares.push({name: item.substring(1,3).toLowerCase(), color: this.getStrategyColor(item.substring(0,1) as ColorAbbr)})
          } else {
            strategy.square.push({name: item.substring(1,3), color: this.getStrategyColor(item.substring(0,1) as ColorAbbr)})
          }
        }
        l.push(strategy)
        position = position2
      } else if (b !== -1 && (a === -1 && c === -1 || a !== -1 && c === -1 && b < a || c !== -1 && a === -1 && b < c || a !== -1 && c !== -1 && b < c && b < c)) {
        if (b > 0) {
          position = b
        }
        const position2: number = line.indexOf(']', position) + 1
        const strategy: Strategy = {which, line: Line, item: n, squares: [], square: [], arrow: []}
        const B: string[] = line.substring(position + 1, position2 - 1).split(',')
        for (const item of B) {
          strategy.arrow.push({startSquare: item.substring(1,3), endSquare: item.substring(3,5), color: this.getStrategyColor(item.substring(0,1) as ColorAbbr)})
        }
        l.push(strategy)
        position = position2
      } else if (c !== -1 && (b === -1 && a === -1 || b !== -1 && a === -1 && c < b || a !== -1 && b === -1 && c < a || b !== -1 && a !== -1 && c < a && c < b)) {
        if (c > 0) {
          position = c
        }
        const position2: number = line.indexOf('>', position) + 1
        const C: string = line.substring(position + 1, position2 - 1)
        const strategy: Strategy = {which, line: Line, item: n, squares: [], square: [], arrow: []}
        const a0: number = C.indexOf('{')
        const a1: number = C.indexOf('}', a0)
        if (a0 !== -1 && a1 !== -1) {
          const AA: string = C.substring(a0 + 1, a1)
          const A: string[] = AA.split(',')
          for (const item of A) {
            if (item.charCodeAt(1) >= 'A'.charCodeAt(0) && item.charCodeAt(1) <= 'H'.charCodeAt(0)) {
              strategy.squares.push({name: item.substring(1,3).toLowerCase(), color: this.getStrategyColor(item.substring(0,1) as ColorAbbr)})
            } else {
              strategy.square.push({name: item.substring(1,3), color: this.getStrategyColor(item.substring(0,1) as ColorAbbr)})
            }
          }
        }
        const b0: number = C.indexOf('[')
        const b1: number = C.indexOf(']', b0)
        if (b0 !== -1 && b1 !== -1) {
          const BB: string = C.substring(b0 + 1, b1)
          const B: string[] = BB.split(',')
          for (const item of B) {
            strategy.arrow.push({startSquare: item.substring(1,3), endSquare: item.substring(3,5), color: this.getStrategyColor(item.substring(0,1) as ColorAbbr)})
          }
        }
        l.push(strategy)
        position = position2
      }
    }
    return {n: nn, l}
  }

  public getStrategyLine(line: string, Line: number, which: number, N: number): {n: number, l: JSX.Element[]} {
    const l: JSX.Element[] = []
    let position = 0
    let nn = 0
    for (let n = 0; ; n++) {
      if (position >= line.length) {nn = n; break}
      const a: number = line.indexOf('{', position)
      const b: number = line.indexOf('[', position)
      const c: number = line.indexOf('<', position)
      if (a === -1 && b === -1 && c === -1) {
        l.push(<span key = {n.toString()}>{line.substring(position, line.length)}</span>)
        nn = n; 
        break
      } else if (a !== -1 && (b === -1 && c === -1 || b !== -1 && c === -1 && a < b || c !== -1 && b === -1 && a < c || b !== -1 && c !== -1 && a < b && a < c)) {
        if (a > 0) {
          l.push(<span key = {n.toString() + '_'}>{line.substring(position, a)}</span>)
          position = a
        }
        const position2: number = line.indexOf('}', position) + 1
        const strategy: Strategy = {which, line: Line, item: n, square: [], squares: [], arrow: []}
        const A: string[] = line.substring(position + 1, position2 - 1).split(',')
        for (const item of A) {
          if (item.charCodeAt(1) >= 'A'.charCodeAt(0) && item.charCodeAt(1) <= 'H'.charCodeAt(0)) {
            strategy.squares.push({name: item.substring(1,3).toLowerCase(), color: this.getStrategyColor(item.substring(0,1) as ColorAbbr)})
          } else {
            strategy.square.push({name: item.substring(1,3), color: this.getStrategyColor(item.substring(0,1) as ColorAbbr)})
          }
        }
        l.push(<button style = {{marginLeft: '2px', marginRight: '2px', color: this.isActiveDiagram(n + N) ? '#dc3545' : ''}} onClick = {() => this.setActiveDiagram(which, Line, n, n + N, strategy)} title = {'A ' + JSON.stringify(strategy)} key = {n.toString()}>
          <FaIcon icon="delicious" type="brand" />&nbsp;{n + N + 1}
        </button>)
        position = position2
      } else if (b !== -1 && (a === -1 && c === -1 || a !== -1 && c === -1 && b < a || c !== -1 && a === -1 && b < c || a !== -1 && c !== -1 && b < c && b < c)) {
        if (b > 0) {
          l.push(<span key = {n.toString() + '_'}>{line.substring(position, b)}</span>)
          position = b
        }
        const position2: number = line.indexOf(']', position) + 1
        const strategy: Strategy = {which, line: Line, item: n, squares: [], square: [], arrow: []}
        const B: string[] = line.substring(position + 1, position2 - 1).split(',')
        for (const item of B) {
          strategy.arrow.push({startSquare: item.substring(1,3), endSquare: item.substring(3,5), color: this.getStrategyColor(item.substring(0,1) as ColorAbbr)})
        }

        l.push(<button style = {{marginLeft: '2px', marginRight: '2px', color: this.isActiveDiagram(n + N) ? '#dc3545' : ''}} onClick = {() => this.setActiveDiagram(which, Line, n, n + N, strategy)} title = {'B ' + JSON.stringify(strategy)} key = {n.toString()}>
          <FaIcon icon="delicious" type="brand"/>&nbsp;{n + N + 1}
          </button>)
        position = position2
      } else if (c !== -1 && (b === -1 && a === -1 || b !== -1 && a === -1 && c < b || a !== -1 && b === -1 && c < a || b !== -1 && a !== -1 && c < a && c < b)) {
        if (c > 0) {
          l.push(<span key = {n.toString() + '_'}>{line.substring(position, c)}</span>)
          position = c
        }
        const position2: number = line.indexOf('>', position) + 1
        const C: string = line.substring(position + 1, position2 - 1)
        const strategy: Strategy = {which, line: Line, item: n, squares: [], square: [], arrow: []}
        const a0: number = C.indexOf('{')
        const a1: number = C.indexOf('}', a0)
        if (a0 !== -1 && a1 !== -1) {
          const AA: string = C.substring(a0 + 1, a1)
          const A: string[] = AA.split(',')
          for (const item of A) {
            if (item.charCodeAt(1) >= 'A'.charCodeAt(0) && item.charCodeAt(1) <= 'H'.charCodeAt(0)) {
              strategy.squares.push({name: item.substring(1,3).toLowerCase(), color: this.getStrategyColor(item.substring(0,1) as ColorAbbr)})
            } else {
              strategy.square.push({name: item.substring(1,3), color: this.getStrategyColor(item.substring(0,1) as ColorAbbr)})
            }
          }
        }
        const b0: number = C.indexOf('[')
        const b1: number = C.indexOf(']', b0)
        if (b0 !== -1 && b1 !== -1) {
          const BB: string = C.substring(b0 + 1, b1)
          const B: string[] = BB.split(',')
          for (const item of B) {
            strategy.arrow.push({startSquare: item.substring(1,3), endSquare: item.substring(3,5), color: this.getStrategyColor(item.substring(0,1) as ColorAbbr)})
          }
        }
        l.push(<button style = {{marginLeft: '2px', marginRight: '2px', color: this.isActiveDiagram(n + N) ? '#dc3545' : ''}} onClick = {() => this.setActiveDiagram(which, Line, n, n + N, strategy)} title = {'C ' + JSON.stringify(strategy)} key = {n.toString()}>
          <FaIcon icon="delicious" type="brand" />&nbsp;{n + N + 1}
        </button>)
        position = position2
      }
    }
    return {n: nn, l}
  }

  public getStrategyText(which: number, strategy: string): JSX.Element[] {
    const s: string[] = strategy.split('|')
    const r: JSX.Element[] = []
    if (s) {
      let n = 0
      for (let i = 0; i < s.length; i++) {
        const l: {n: number, l: JSX.Element[]} = this.getStrategyLine(s[i], i, which, n)
        n += l.n
        r.push(<div key = {i.toString()}>
          {l.l}
        </div>)
      }
    }
    return r
  }

  render() {
    if (this.state.move === undefined) {
      this.setState({ move: [] })
      this.setMove()
    }
    const { fen, dropSquareStyle, squareStyles, turn } = this.state;
    const vw: number = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const vh: number = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    const width: string = ((vw - vh) / 2) + 'px'
    const w = screen.width
    const h = screen.height
    return this.state.admin ? <div className = "pr">
      <div className="clearfix" style={
        w < h && w <= 1024 ?
          { zIndex: 9999, textAlign: 'center', position: 'absolute', top: -27, bottom: 'auto', left: 95, width: '86px', height: '30px', padding: '0px' } :
          w > h && w <= 1024 ?
            { zIndex: 9999, textAlign: 'center', position: 'absolute', top: 0, bottom: 'auto', left: h + 95, width: '86px', height: '30px', padding: '0px' } :

            { zIndex: 9999, textAlign: 'center', position: 'absolute', top: 5, bottom: 'auto', left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto', width: '86px', height: '30px', padding: '0px' }}>
        <button onClick={() => this.setState({ admin: !this.state.admin })} type="button" style={{ position: 'absolute', top: '5px', right: '-570px', lineHeight: 1.5, padding: '2px 6px', background: '#28a745', color: '#fff', borderRadius: '3px'  }}>
          admin mode
        </button>
      </div>
      <div style={{ position: 'absolute', top: -3, left: 0, right: 0, bottom: 0, padding: '15px', fontWeight: 400 }}>
        <input type = "radio" checked = {this.state.adminMode === true} onChange={() => this.setState({ adminMode: !this.state.adminMode })}/>
        &nbsp;edit positions
        <input style = {{marginLeft: '10px'}} type = "radio" checked = {this.state.adminMode === false} onChange={() => this.setState({ adminMode: !this.state.adminMode })}/>
        &nbsp;view users
      </div>

      {!this.state.adminMode ? 
        <div style = {{position: 'absolute', top: 30, left: 0, right: 0, bottom: 0, padding: '15px'}}>
          {this.state.customer.map((item) => <div style = {{marginBottom: '10px', display: 'block'}} key = {item.uuid}><button title = {item.admin ? 'admin' : 'customer'} type = "button" onClick = {() => this.toggleCustomerAdmin(item.uuid)} style = {{lineHeight: 1.5, padding: '2px 6px', background: item.admin ? '#007bff' : '#6c757d', color: '#fff', borderRadius: '3px', marginRight: '5px'}}>
            {item.username} - {item.group} {item.position}
          </button></div>)}
        </div> : null}

      <div style={{ position: 'absolute', top: 10, left: 210, right: 0, bottom: 0, padding: '15px' }}>
        <div style={{ marginTop: '-15px'}}>
          {this.state.group.map((item) => <button onClick={() => this.selectGroup(item.uuid)} key={item.uuid} type="button" style={{ border: '1px solid #999', lineHeight: 1.4, padding: '2px 6px', background: item.uuid === this.state.groupsuuid ? '#ffc107' : '#fff', borderRadius: '3px', marginRight: '5px' }}>
            {item.position}&nbsp;-&nbsp;{item.name}&nbsp;&nbsp;<span style={{ background: '#343a40', color: '#fff', borderRadius: '5px', padding: '1px 3px', fontWeight: 700 }}>{item.count}</span>
          </button>)}
        </div>
        {this.state.adminMode && this.state.groupsuuid && this.state.groupIndex !== -1 ?
          <div style={{ marginTop: '10px', position: 'relative' }}>
            <div style={{ position: 'absolute', top: '-35px', right: '0px' }}>
              <div style={{ fontWeight: 400, fontSize: '11px' }}>Change group name (ENG)</div>
              <div>
                <input type="text" style={{ background: '#fff', height: '24px', width: '130px', fontSize: '16px', paddingLeft: '5px' }} onChange={(e) => this.setGroupName('name', e.target.value)} value={this.state.group[this.state.groupIndex].name} />
              </div>
            </div>
            <div style={{ position: 'absolute', top: '-35px', right: '135px' }}>
              <div style={{ fontWeight: 400, fontSize: '11px' }}>Change group name (SLO)</div>
              <div>
                <input type="text" style={{ background: '#fff', height: '24px', width: '130px', fontSize: '16px', paddingLeft: '5px' }} onChange={(e) => this.setGroupName('namesl', e.target.value)} value={this.state.group[this.state.groupIndex].namesl} />
              </div>
            </div>
            <div style={{ position: 'absolute', top: '-35px', right: '270px' }}>
              <div style={{ fontWeight: 400, fontSize: '11px' }}>Group index</div>
              <div>
                <input type="number" style={{ background: '#fff', height: '24px', width: '55px', fontSize: '16px', paddingLeft: '5px', paddingRight: '5px' }} onChange={(e) => this.setGroupPosition('position', +e.target.value)} value={this.state.group[this.state.groupIndex].position} />
              </div>
            </div>
            <div style={{ position: 'absolute', top: '-5px', left: '-210px' }}>
            <div style={{ fontWeight: 400, fontSize: '13px', width: '1000px' }}>
              <span>Insert a new position</span>
              <input style={{ marginLeft: '15px' }} type="radio" checked={this.state.newWin} onChange={() => this.setState({ newWin: !this.state.newWin })} />
              <span style={{ marginLeft: '5px' }}>play for win</span>
              <input style={{ marginLeft: '10px' }} type="radio" checked={!this.state.newWin} onChange={() => this.setState({ newWin: !this.state.newWin })} />
              <span style={{ marginLeft: '5px' }}>play for draw</span>

              <input style={{ marginLeft: '25px' }} type="radio" checked={this.state.newMate} onChange={() => this.setState({ newMate: !this.state.newMate })} />
              <span style={{ marginLeft: '5px' }}>play for mate</span>
              <input style={{ marginLeft: '10px' }} type="radio" checked={!this.state.newMate} onChange={() => this.setState({ newMate: !this.state.newMate })} />
              <span style={{ marginLeft: '5px' }}>not a mate</span>

            </div>
            <div>
              <input type="text" style={{ background: '#fff', height: '24px', width: 'calc(100% - 23px)', fontSize: '16px', paddingLeft: '5px' }} onChange={(e) => this.setState({ newFen: e.target.value })} value={this.state.newFen} />
              <FaIcon style={{ marginLeft: '5px', fontSize: '20px', color: '#28a745' }} onClick={() => this.insertNewFen()} type="solid" icon='plus-square' />
            </div>
          </div>
          </div> : null}

        {this.state.adminMode && this.state.groupsuuid ?
        <>
        <button onClick = {() => this.setPosition0(false)} type="button" style={{ border: '1px solid #999', position: 'absolute', top: '50px', right: '10px', lineHeight: 1.4, padding: '2px 6px', background:  '#fff', borderRadius: '3px'}}>First pos.</button>
        <button onClick = {() => this.setPosition0(true)} type="button" style={{ border: '1px solid #999', position: 'absolute', top: '50px', right: '80px', lineHeight: 1.4, padding: '2px 6px', background:  '#fff', borderRadius: '3px'}}>Last pos.</button>

        <button onClick = {() => this.setPosition2(this.state.position[0].nextuuid, true)} disabled = {this.state.positionposition === this.getCountPositionsInGroup()} type="button" style={{ border: '1px solid #999', position: 'absolute', top: '50px', right: '148px', lineHeight: 1.4, padding: '2px 6px', background:  '#fff', borderRadius: '3px'}}>Next pos.</button>
        <button onClick = {() => this.setPosition2(this.state.position[0].prevuuid, false)} disabled = {this.state.positionposition === 1} type="button" style={{ border: '1px solid #999', position: 'absolute', top: '50px', right: '219px', lineHeight: 1.4, padding: '2px 6px', background:  '#fff', borderRadius: '3px'}}>Previous pos.</button>
        <select style = {{position: 'absolute', top: '50px', right: '314px', background: '#fff', borderRadius: '5px', fontSize: '16px'}} onChange = {(e) => this.setPositionPosition(+e.target.value)} value = {this.state.positionposition}>{this.getPositionOptions()}</select>
        </>
        : null}
        {this.state.adminMode && this.state.position.length ?
          <>
            <ReactTable
              style={{ position: 'absolute', top: '80px', left: '-200px', width: 'calc(100% + 190px)'}}
              data={this.state.position}
              columns={[
                {
                  resizable: false, sortable: false, Header: <div style = {{position: 'relative', width: '100%'}}>
                    <span style ={{position: 'absolute', left: 225}}>Starting position</span>
                    <button type = "button" onClick = {() => this.setState({mode: true})} style = {{padding: '0px 6px', position: 'absolute', color: this.state.mode ? '#fff' : '', background: this.state.mode ? '#dc3545' : '#fff', left: 0, top: '2px', borderRadius: '3px'}}>Override engine move</button>
                    <button type = "button" onClick = {() => this.setState({mode: false})} style = {{padding: '0px 6px', position: 'absolute', color: !this.state.mode ? '#fff' : '', background: !this.state.mode ? '#dc3545' : '#fff', right: 0, top: '2px', borderRadius: '3px'}}>Forced line</button>
                  </div>, className: 'rt-smallrow ta-center', headerClassName: 'ta-center', columns: [

                    { resizable: false, sortable: false, width: 51, Header: 'Index', className: 'rt-smallrow ta-left', headerClassName: 'ta-left', accessor: 'uuid', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                      <button title = "Set my current position to this puzzle" style = {{color: '#fff', background: this.state.positionuuid === row.value ? '#dc3545' : '#111', borderRadius: '3px', width: '90%', marginLeft: '5%', lineHeight: 1.2}} onClick = {() => this.setPosition(row.value)}>{this.state.positionposition}</button>
                    </div> },
                    {
                      resizable: false, sortable: false, width: 30, Header: <FaIcon icon="trash-alt" type="solid" />, className: 'rt-smallrow ta-center', headerClassName: 'ta-center', accessor: 'groupuuid', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <FaIcon onClick={() => this.deletePosition(row.index, row.original.uuid, row.original.prevuuid, row.original.nextuuid)} style={{ color: '#dc3545' }} icon="trash-alt" type="solid" />
                      </div>
                    },
                    {
                      resizable: false, sortable: false, width: 30, Header: <FaIcon icon="bullseye" type="solid" />, className: 'rt-smallrow ta-center', headerClassName: 'ta-center', accessor: 'win', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <span style={{ cursor: 'pointer' }} onClick={() => this.editElementBoolean(row.index, 'win', !row.value)} title={row.value ? 'play for win' : 'play for draw'}>{row.value ? 'W' : '½'}</span>
                      </div>
                    },
                    {
                      resizable: false, sortable: false, width: 30, Header: <FaIcon icon="hashtag" type="solid" />, className: 'rt-smallrow ta-center', headerClassName: 'ta-center', accessor: 'mate', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <span style={{ cursor: 'pointer' }} onClick={() => this.editElementBoolean(row.index, 'mate', !row.value)} title={row.value ? 'play for mate' : 'not a mate'}><FaIcon style={{ color: row.value ? '#28a745' : '#dc3545' }} icon={row.value ? 'check' : 'times'} type="solid" /></span>
                      </div>
                    },

                    {
                      resizable: false, sortable: false, width: 30, Header: <FaIcon icon="eye" type="solid" />, className: 'rt-smallrow ta-center', headerClassName: 'ta-center', accessor: 'fen', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <span style={{ cursor: 'pointer' }} onClick={() => this.showChessboard(row.original.variant, row.value, row.original.uuid, row.index)}><FaIcon style={{ color: row.original.uuid === this.state.positionUuid ? '#28a745' : '' }} icon="delicious" type="brand" /></span>
                      </div>
                    },
                    {
                      resizable: false, sortable: false, width: 58, Header: 'valid', className: 'rt-smallrow ta-center', headerClassName: 'ta-center', accessor: 'fen', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        {validateFEN(row.value) ? 'YES' : <span><FaIcon style={{ color: '#dc3545' }} icon="exclamation-triangle" type="solid" />&nbsp;NO</span>}
                      </div>
                    },
                    {
                      resizable: false, sortable: false, width: 250, Header: 'FEN', className: 'rt-smallrow ta-left', headerClassName: 'ta-left', accessor: 'fen', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <input type="text" onChange={(e) => this.editElement(row.index, 'fen', e.target.value)} value={row.value} style={{ height: '22px', width: '100%', fontSize: '16px' }} />
                      </div>
                    },
                    {
                      resizable: false, sortable: false, show: this.state.mode, width: 25, Header: <FaIcon icon="plus-square" type="solid" />, className: 'rt-smallrow ta-center', headerClassName: 'ta-center', accessor: 'uuid', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <span style={{ cursor: 'pointer' }} onClick={() => this.insertMove(row.index, row.original.uuid)}><FaIcon style={{ color: '#007bff' }} icon="plus-square" type="solid" /></span>
                      </div>
                    },

                  ]
                },
                {
                  resizable: false, sortable: false, show: !this.state.mode, Header: 'Forced line', className: 'rt-smallrow ta-center', headerClassName: 'ta-center', columns: [
                    {resizable: false, sortable: false, show: !this.state.mode, width: 689, accessor: 'variant', Header: 'Edit forced line', className: 'rt-smallrow ta-left', headerClassName: 'ta-center darker', Cell: (row) => 
                      <input maxLength = {110} type = "text" onChange={(e) => this.editElement(row.index, 'variant', e.target.value)} style={{ height: '22px', width: '100%', fontSize: '15px', background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }} value = {row.value ? row.value : ''}/>},
                    {resizable: false, sortable: false, show: !this.state.mode, width: 720, accessor: '', Header: 'Show forced line', className: 'rt-smallrow ta-left', headerClassName: 'ta-center', Cell: (row) => 
                      <div style={{ fontWeight: 400, fontSize: '15px', background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>{this.showForcedLine(row.original.fen, row.original.variant)}</div>},
                  ]
                },
                {
                  resizable: false, sortable: false, show: this.state.mode, Header: 'Override engine move #1', className: 'rt-smallrow ta-center', headerClassName: 'ta-center darker', columns: [
                    {
                      resizable: false, sortable: false, show: this.state.mode, width: 25, Header: <FaIcon title = "color to play" icon="tint" type="solid" />, className: 'rt-smallrow ta-center', headerClassName: 'ta-center', accessor: '', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <FaIcon onClick={() => this.editElement(row.index, 'color', this.getElement(row.index, 'color', 1), 1)} type={this.getElement(row.index, 'color', 1) as ColorType === 'b' ? 'solid' : 'regular'} icon="square" />
                      </div>
                    },
                    {
                      resizable: false, sortable: false, show: this.state.mode, width: 23, Header: <span title="piece to play">P</span>, className: 'rt-smallrow ta-center', headerClassName: 'ta-center', accessor: '', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <img onClick={() => this.editElement(row.index, 'piece', this.getElement(row.index, 'piece', 1), 1)} style={{ marginLeft: '-5px', cursor: 'pointer', display: 'block', paddingTop: '0px', paddingBottom: '0px' }} height={22} width={22} src={this.getPIECE(this.getElement(row.index, 'color', 1) as ColorType, this.getElement(row.index, 'piece', 1) as PieceType)} /></div>
                    },

                    {
                      resizable: false, sortable: false, show: this.state.mode, width: 24, Header: <span title="source square">S</span>, className: 'rt-smallrow ta-center', headerClassName: 'ta-center', accessor: '', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <input type="text" onChange={(e) => this.editElement(row.index, 'source', e.target.value, 1)} value={this.getElement(row.index, 'source', 1)} style={{ paddingLeft: '0px', paddingRight: '0px', marginLeft: '-5px', textAlign: 'center', height: '22px', width: '22px', fontSize: '16px' }} /></div>
                    },
                    {
                      resizable: false, sortable: false, show: this.state.mode, width: 24, Header: <span title="target square">T</span>, className: 'rt-smallrow ta-center', headerClassName: 'ta-center', accessor: '', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <input type="text" onChange={(e) => this.editElement(row.index, 'target', e.target.value, 1)} value={this.getElement(row.index, 'target', 1)} style={{ paddingLeft: '0px', paddingRight: '0px', marginLeft: '-5px', textAlign: 'center', height: '22px', width: '22px', fontSize: '16px' }} /></div>
                    },

                    {
                      resizable: false, sortable: false, show: this.state.mode, width: 250, Header: 'FEN', className: 'rt-smallrow ta-left', headerClassName: 'ta-left', accessor: '', Cell: (row) => <div className="por" style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <input type="text" onChange={(e) => this.editElement(row.index, 'fen', e.target.value, 1)} value={this.getElement(row.index, 'fen', 1)} style={{ height: '22px', width: '100%', fontSize: '16px' }} />{this.moveExists(row.index, 1) ? <FaIcon style={{ color: this.moveExistsPiece(row.index, 1) ? '#dc3545' : '#ffc107', position: 'absolute', right: '2px', top: '2px' }} onClick={() => this.deleteMove(row.index, row.original.uuid, 1)} type="solid" icon="trash-alt" /> : null}</div>
                    },
                  ]
                },

                {
                  resizable: false, sortable: false, show: this.state.mode, Header: 'Override engine move #2', className: 'rt-smallrow ta-center', headerClassName: 'ta-center', columns: [
                    {
                      resizable: false, sortable: false, show: this.state.mode, width: 25, Header: <FaIcon icon="tint" type="solid" />, className: 'rt-smallrow ta-center', headerClassName: 'ta-center', accessor: '', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <FaIcon onClick={() => this.editElement(row.index, 'color', this.getElement(row.index, 'color', 2), 2)} type={this.getElement(row.index, 'color', 2) as ColorType === 'b' ? 'solid' : 'regular'} icon="square" />
                      </div>
                    },
                    {
                      resizable: false, sortable: false, show: this.state.mode, width: 23, Header: <span title="piece to play">P</span>, className: 'rt-smallrow ta-center', headerClassName: 'ta-center', accessor: '', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <img onClick={() => this.editElement(row.index, 'piece', this.getElement(row.index, 'piece', 2), 2)} style={{ marginLeft: '-5px', cursor: 'pointer', display: 'block', paddingTop: '0px', paddingBottom: '0px' }} height={22} width={22} src={this.getPIECE(this.getElement(row.index, 'color', 2) as ColorType, this.getElement(row.index, 'piece', 2) as PieceType)} /></div>
                    },

                    {
                      resizable: false, sortable: false, show: this.state.mode, width: 24, Header: <span title="source square">S</span>, className: 'rt-smallrow ta-center', headerClassName: 'ta-center', accessor: '', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <input type="text" onChange={(e) => this.editElement(row.index, 'source', e.target.value, 2)} value={this.getElement(row.index, 'source', 2)} style={{ paddingLeft: '0px', paddingRight: '0px', marginLeft: '-5px', textAlign: 'center', height: '22px', width: '22px', fontSize: '16px' }} /></div>
                    },
                    {
                      resizable: false, sortable: false, show: this.state.mode, width: 24, Header: <span title="target square">T</span>, className: 'rt-smallrow ta-center', headerClassName: 'ta-center', accessor: '', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <input type="text" onChange={(e) => this.editElement(row.index, 'target', e.target.value, 2)} value={this.getElement(row.index, 'target', 2)} style={{ paddingLeft: '0px', paddingRight: '0px', marginLeft: '-5px', textAlign: 'center', height: '22px', width: '22px', fontSize: '16px' }} /></div>
                    },

                    {
                      resizable: false, sortable: false, show: this.state.mode, width: 250, Header: 'FEN', className: 'rt-smallrow ta-left', headerClassName: 'ta-left', accessor: '', Cell: (row) => <div className="por" style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <input type="text" onChange={(e) => this.editElement(row.index, 'fen', e.target.value, 2)} value={this.getElement(row.index, 'fen', 2)} style={{ height: '22px', width: '100%', fontSize: '16px' }} />{this.moveExists(row.index, 2) ? <FaIcon style={{ color: this.moveExistsPiece(row.index, 2) ? '#dc3545' : '#ffc107', position: 'absolute', right: '2px', top: '2px' }} onClick={() => this.deleteMove(row.index, row.original.uuid, 2)} type="solid" icon="trash-alt" /> : null}</div>
                    },
                  ]
                },

                {
                  resizable: false, sortable: false, show: this.state.mode, Header: 'Override engine move #3', className: 'rt-smallrow ta-center', headerClassName: 'ta-center darker', columns: [
                    {
                      resizable: false, sortable: false, show: this.state.mode, width: 25, Header: <FaIcon icon="tint" type="solid" />, className: 'rt-smallrow ta-center', headerClassName: 'ta-center', accessor: '', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <FaIcon onClick={() => this.editElement(row.index, 'color', this.getElement(row.index, 'color', 3), 3)} type={this.getElement(row.index, 'color', 3) as ColorType === 'b' ? 'solid' : 'regular'} icon="square" />
                      </div>
                    },

                    {
                      resizable: false, sortable: false, show: this.state.mode, width: 23, Header: <span title="piece to play">P</span>, className: 'rt-smallrow ta-center', headerClassName: 'ta-center', accessor: '', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <img onClick={() => this.editElement(row.index, 'piece', this.getElement(row.index, 'piece', 3), 3)} style={{ marginLeft: '-5px', cursor: 'pointer', display: 'block', paddingTop: '0px', paddingBottom: '0px' }} height={22} width={22} src={this.getPIECE(this.getElement(row.index, 'color', 3) as ColorType, this.getElement(row.index, 'piece', 3) as PieceType)} /></div>
                    },

                    {
                      resizable: false, sortable: false, show: this.state.mode, width: 24, Header: <span title="source square">S</span>, className: 'rt-smallrow ta-center', headerClassName: 'ta-center', accessor: '', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <input type="text" onChange={(e) => this.editElement(row.index, 'source', e.target.value, 3)} value={this.getElement(row.index, 'source', 3)} style={{ paddingLeft: '0px', paddingRight: '0px', marginLeft: '-5px', textAlign: 'center', height: '22px', width: '22px', fontSize: '16px' }} /></div>
                    },
                    {
                      resizable: false, sortable: false, show: this.state.mode, width: 24, Header: <span title="target square">T</span>, className: 'rt-smallrow ta-center', headerClassName: 'ta-center', accessor: '', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <input type="text" onChange={(e) => this.editElement(row.index, 'target', e.target.value, 3)} value={this.getElement(row.index, 'target', 3)} style={{ paddingLeft: '0px', paddingRight: '0px', marginLeft: '-5px', textAlign: 'center', height: '22px', width: '22px', fontSize: '16px' }} /></div>
                    },

                    {
                      resizable: false, sortable: false, show: this.state.mode, width: 250, Header: 'FEN', className: 'rt-smallrow ta-left', headerClassName: 'ta-left', accessor: '', Cell: (row) => <div className="por" style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <input type="text" onChange={(e) => this.editElement(row.index, 'fen', e.target.value, 3)} value={this.getElement(row.index, 'fen', 3)} style={{ height: '22px', width: '100%', fontSize: '16px' }} />{this.moveExists(row.index, 3) ? <FaIcon style={{ color: this.moveExistsPiece(row.index, 3) ? '#dc3545' : '#ffc107', position: 'absolute', right: '2px', top: '2px' }} onClick={() => this.deleteMove(row.index, row.original.uuid, 3)} type="solid" icon="trash-alt" /> : null}</div>
                    },
                  ]
                },

                {
                  resizable: false, sortable: false, show: this.state.mode, Header: 'Override engine move #4', className: 'rt-smallrow ta-center', headerClassName: 'ta-center', columns: [
                    {
                      resizable: false, sortable: false, show: this.state.mode, width: 25, Header: <FaIcon icon="tint" type="solid" />, className: 'rt-smallrow ta-center', headerClassName: 'ta-center', accessor: '', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <FaIcon onClick={() => this.editElement(row.index, 'color', this.getElement(row.index, 'color', 4), 4)} type={this.getElement(row.index, 'color', 4) as ColorType === 'b' ? 'solid' : 'regular'} icon="square" />
                      </div>
                    },

                    {
                      resizable: false, sortable: false, show: this.state.mode, width: 23, Header: <span title="piece to play">P</span>, className: 'rt-smallrow ta-center', headerClassName: 'ta-center', accessor: '', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <img onClick={() => this.editElement(row.index, 'piece', this.getElement(row.index, 'piece', 4), 4)} style={{ marginLeft: '-5px', cursor: 'pointer', display: 'block', paddingTop: '0px', paddingBottom: '0px' }} height={22} width={22} src={this.getPIECE(this.getElement(row.index, 'color', 4) as ColorType, this.getElement(row.index, 'piece', 4) as PieceType)} /></div>
                    },

                    {
                      resizable: false, sortable: false, show: this.state.mode, width: 24, Header: <span title="source square">S</span>, className: 'rt-smallrow ta-center', headerClassName: 'ta-center', accessor: '', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <input type="text" onChange={(e) => this.editElement(row.index, 'source', e.target.value, 4)} value={this.getElement(row.index, 'source', 4)} style={{ paddingLeft: '0px', paddingRight: '0px', marginLeft: '-5px', textAlign: 'center', height: '22px', width: '22px', fontSize: '16px' }} /></div>
                    },
                    {
                      resizable: false, sortable: false, show: this.state.mode, width: 24, Header: <span title="target square">T</span>, className: 'rt-smallrow ta-center', headerClassName: 'ta-center', accessor: '', Cell: (row) => <div style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <input type="text" onChange={(e) => this.editElement(row.index, 'target', e.target.value, 4)} value={this.getElement(row.index, 'target', 4)} style={{ paddingLeft: '0px', paddingRight: '0px', marginLeft: '-5px', textAlign: 'center', height: '22px', width: '22px', fontSize: '16px' }} /></div>
                    },

                    {
                      resizable: false, sortable: false, show: this.state.mode, width: 250, Header: 'FEN', className: 'rt-smallrow ta-left', headerClassName: 'ta-left', accessor: '', Cell: (row) => <div className="por" style={{ background: !validateFEN(row.original.fen) ? '#fdd' : row.original.uuid === this.state.positionuuid ? '#dfd' : '#ddf' }}>
                        <input type="text" onChange={(e) => this.editElement(row.index, 'fen', e.target.value, 4)} value={this.getElement(row.index, 'fen', 4)} style={{ height: '22px', width: '100%', fontSize: '16px' }} />{this.moveExists(row.index, 4) ? <FaIcon style={{ color: this.moveExistsPiece(row.index, 4) ? '#dc3545' : '#ffc107', position: 'absolute', right: '2px', top: '2px' }} onClick={() => this.deleteMove(row.index, row.original.uuid, 4)} type="solid" icon="trash-alt" /> : null}</div>
                    },
                  ]
                }
              ]}
              showPaginationTop={false}
              showPaginationBottom={false}
              pageSize={1}
              page={this.state.pNum}
            />
            {this.state.Fen ?
              <div style={{ position: 'absolute', left: '-200px', top: '170px' }} className="clearfix">
                <div className={this.state.background ? 'cgwrap' : ''} style={{ float: 'left', position: 'relative', width: '533px' }}>
                  <FaIcon style={{ position: 'absolute', right: '3px', top: '0px' }} onClick={() => this.setState({ background: false, Fen: undefined, positionUuid: undefined })} type="solid" icon="times" />
                  <Chessground orientation={this.state.orientation === 'b' ? 'black' : 'white'} viewOnly={true} coordinates={true} fen={this.state.Fen} />
                </div>
                <div className="clearfix" style={{ float: 'left', width: 'calc(100% - 533px)' }}>
                  {this.getMoves()}
                </div>
              </div> :
              <div style={{ position: 'absolute', left: '-200px', top: '170px' }} className="clearfix">
                {this.getPositions()}
              </div>}

              {this.state.groupIndex === 2 ? 
              <>
                <div style = {{ position: 'absolute', left: '580px', top: '175px', width: '600px', height: '768px', background: '#dff'}}>
                  <textarea maxLength = {MAX_CHARACTERS_ENG_LONG} onChange = {(e) => this.editTextarea('textenglong', e.target.value)} style = {{background: 'transparent',resize: 'none', padding: '5px', height: '100%', width: '100%'}} value = {this.state.textenglong}/>
                </div>
                <div style = {{ position: 'absolute', left: '1190px', top: '175px', width: '250px', height: '768px', background: '#fdf'}}>
                  <textarea maxLength = {MAX_CHARACTERS_ENG_SHORT} onChange = {(e) => this.editTextarea('textengshort', e.target.value)} style = {{background: 'transparent',resize: 'none', padding: '5px', height: '100%', width: '100%'}} value = {this.state.textengshort}/>
                </div>
                <div style = {{ position: 'absolute', left: '1450px', top: '175px', width: '250px', height: '768px', background: '#ffd'}}>
                  <textarea maxLength = {MAX_CHARACTERS_SLO_SHORT} onChange = {(e) => this.editTextarea('textslshort', e.target.value)} style = {{background: 'transparent', resize: 'none', padding: '5px', height: '100%', width: '100%'}} value = {this.state.textslshort}/>
                </div>

                <img style = {{ position: 'absolute', left: '580px', top: '942px', height: 20}} src = {uk}/>
                <img style = {{ position: 'absolute', left: '1190px', top: '942px', height: 20}} src = {usa}/>
                <img style = {{ position: 'absolute', left: '1450px', top: '942px', height: 20}} src = {slo}/>
                
                <button onClick = {() => this.saveChanges()} style = {{lineHeight: 1.3, padding: '2px 6px', background: '#dc3545', color: '#fff', borderRadius: '3px', position: 'absolute', left: '623px', top: '942px'}}>
                  Save changes
                </button>

                <button title = "characters limit" style = {{lineHeight: 1.3, padding: '2px', textAlign: 'center', background: '#ffc107', color: '#333', borderRadius: '3px', position: 'absolute', left: '717px', top: '942px', width: '38px'}}>
                  {MAX_CHARACTERS_ENG_LONG}
                </button>

                <button title = "characters left" style = {{lineHeight: 1.2, padding: '2px', textAlign: 'center', border: '1px solid #999', background: '#fff', color: '#333', borderRadius: '3px', position: 'absolute', left: '758px', top: '942px', width: '35px'}}>
                  {MAX_CHARACTERS_ENG_LONG - this.state.textenglong.length}
                </button>

                <button title = "characters" style = {{lineHeight: 1.2, padding: '2px', textAlign: 'center', border: '1px solid #999', background: '#007bff', color: '#fff', borderRadius: '3px', position: 'absolute', left: '795px', top: '942px', width: '35px'}}>
                  {this.state.textenglong.length}
                </button>

                <button title = "characters limit" style = {{lineHeight: 1.3, padding: '2px', textAlign: 'center', background: '#ffc107', color: '#333', borderRadius: '3px', position: 'absolute', left: '1327px', top: '942px', width: '38px'}}>
                  {MAX_CHARACTERS_ENG_SHORT}
                </button>

                <button title = "characters left" style = {{lineHeight: 1.2, padding: '2px', textAlign: 'center', border: '1px solid #999', background: '#fff', color: '#333', borderRadius: '3px', position: 'absolute', left: '1368px', top: '942px', width: '35px'}}>
                  {MAX_CHARACTERS_ENG_SHORT - this.state.textengshort.length}
                </button>

                <button title = "characters" style = {{lineHeight: 1.2, padding: '2px', textAlign: 'center', border: '1px solid #999', background: '#007bff', color: '#fff', borderRadius: '3px', position: 'absolute', left: '1405px', top: '942px', width: '35px'}}>
                  {this.state.textengshort.length}
                </button>

                <button title = "characters limit" style = {{lineHeight: 1.3, padding: '2px', textAlign: 'center', background: '#ffc107', color: '#333', borderRadius: '3px', position: 'absolute', left: '1587px', top: '942px', width: '38px'}}>
                  {MAX_CHARACTERS_SLO_SHORT}
                </button>

                <button title = "characters left" style = {{lineHeight: 1.2, padding: '2px', textAlign: 'center', border: '1px solid #999', background: '#fff', color: '#333', borderRadius: '3px', position: 'absolute', left: '1628px', top: '942px', width: '35px'}}>
                  {MAX_CHARACTERS_SLO_SHORT - this.state.textslshort.length}
                </button>
                
                <button title = "characters" style = {{lineHeight: 1.2, padding: '2px', textAlign: 'center', border: '1px solid #999', background: '#007bff', color: '#fff', borderRadius: '3px', position: 'absolute', left: '1665px', top: '942px', width: '35px'}}>
                  {this.state.textslshort.length}
                </button>

                <div className = "clearfix" style = {{height: '20px', width: 'calc(100% + 200px)', top: '965px', left: '-200px', position: 'absolute'}}>
                  {this.getColors()}
                </div>
              </>
              : null}
          </> : null}
      </div>
    </div>
      :
      <div id = "h2h" className = "pr">
        {this.state.Admin ?
          <div className="clearfix" style={
            w < h && w <= 1024 ?
              { zIndex: 9999, textAlign: 'center', position: 'absolute', top: -27, bottom: 'auto', left: 95, width: '86px', height: '30px', padding: '0px' } :
              w > h && w <= 1024 ?
                { zIndex: 9999, textAlign: 'center', position: 'absolute', top: 0, bottom: 'auto', left: h + 95, width: '86px', height: '30px', padding: '0px' } :

                { zIndex: 9999, textAlign: 'center', position: 'absolute', top: 5, bottom: 'auto', left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto', width: '86px', height: '30px', padding: '0px' }
          }>
            <button onClick={() => this.gotoAdminMode()} type="button" style={{ position: 'absolute', top: '5px', right: '-570px', lineHeight: 1.5, padding: '2px 6px', background: '#dc3545', color: '#fff', borderRadius: '3px' }}>
              admin mode
          </button>
          </div> : null}
        <div className="clearfix" style={
          w < h && w <= 1024 ?
            { zIndex: 9998, position: 'absolute', top: -27, bottom: 'auto', left: 5, right: 5, height: '30px', padding: '0px' } :
            w > h && w <= 1024 ?
              { zIndex: 9998, position: 'absolute', top: 5, bottom: 'auto', left: h + 5, right: 0, marginLeft: 'auto', marginRight: 'auto', width: 'calc(100% - ' + (h + 10) + 'px)', height: '30px', padding: '0px' } :

              { zIndex: 9998, position: 'absolute', top: 20, bottom: 'auto', left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto', width: (vh - 100) + 'px', height: '30px', padding: '0px' }
        }>
          {this.state.user ?
            <>
              <button disabled = {this.state.groupPosition === 1 && this.state.grouppos === 1} onClick={() => this.backward()} style={{ cursor: this.state.groupPosition === 1 && this.state.grouppos === 1 ? 'default' : 'pointer', border: '0px', display: 'block', marginTop: '-6px', float: 'left', fontSize: '28px'}}>
                <FaIcon title = {messages[this.state.language].goBackward} style={{ color: this.state.groupPosition === 1 && this.state.grouppos === 1 ? '' : '#dc3545' }} type="solid" icon="backward" />
              </button>
              {this.state.getPosition ? <FaIcon title = {messages[this.state.language].refresh} onClick={() => this.refresh()} style={{ display: 'block', float: 'left', fontSize: '24px', marginLeft: '11px' }} type="solid" icon="sync-alt" /> : null}
              <button title = {this.state.playForWin && this.state.playForMate && this.state.matingSquare.length === 0 ? messages[this.state.language].goForwardBanned : messages[this.state.language].goForward} disabled = {this.state.playForWin && this.state.playForMate && this.state.matingSquare.length === 0} onClick={() => this.forward()} style={{ border: '0px', cursor: this.state.playForWin && this.state.playForMate && this.state.matingSquare.length === 0 ? 'default' : 'pointer', color: this.state.playForWin && this.state.playForMate && this.state.matingSquare.length || !this.state.playForMate && this.state.message ? '#28a745' : '', marginTop: '-6px', float: 'left', fontSize: '28px', marginLeft: '11px' }} className = "fl">
                <FaIcon type="solid" icon="forward" /><FaIcon type="solid" icon="forward" />
              </button>
              {this.state.groupPosition === 3 ? 
              <button disabled = {this.state.activeDiagram.n === this.state.strategy.length - 1} onClick={() => this.Forward()} style={{ border: '0px', cursor: this.state.activeDiagram.n === this.state.strategy.length - 1 ? 'default' : 'pointer', color: this.state.activeDiagram.n === this.state.strategy.length - 1 ? '' : '#28a745', marginTop: '-6px', float: 'left', fontSize: '28px', marginLeft: '11px' }}>
                <FaIcon type="solid" icon="forward" />
            </button>
              : null}
            </> : null}
          <div style={{ float: 'right', fontWeight: 700, fontSize: w > h && w <= 1024 ? '21px' : '24px'}}>
            <FaIcon title = {this.state.turn === 'b' ? messages[this.state.language].white : messages[this.state.language].black} type={this.state.turn === 'b' ? 'regular' : 'solid'} icon="square" />
          </div>
        </div>
        {this.state.user && this.state.message ?
          <div style={
            w < h && w <= 1024 ?
              { fontSize: '20px', position: 'absolute', top: w + 70, left: 5, height: '30px', padding: '0px' } :
              w > h && w <= 1024 ?
                { fontSize: '20px', position: 'absolute', top: 80, left: h + 10, right: 5, height: '30px', padding: '0px' } :

                { fontSize: '20px', position: 'absolute', top: 20, bottom: 'auto', left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto', width: (vh - 100) + 'px', height: '30px', padding: '0px' }}>
            <span style={{ marginLeft: w <= 1024 ? '0px' : '140px' }}>{this.state.message}</span>&nbsp;{this.state.message2 ? <small>{this.state.message2}</small> : null}
          </div>
          : null}
        {this.state.user && this.state.groupname ?
          <div style={
            w < h && w <= 1024 ?
              { fontSize: '20px', position: 'absolute', top: w + 40, left: 5, height: '30px', padding: '0px' } :
              w > h && w <= 1024 ?
                { fontSize: '20px', position: 'absolute', bottom: -5, left: h + 5, height: '30px', padding: '0px' } :
                { fontSize: '20px', position: 'absolute', bottom: 13, top: 'auto', left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto', width: (vh - 100) + 'px', height: '30px', padding: '0px' }
          }>
            <span title = {messages[this.state.language].chapter} style={{ marginLeft: w <= 1024 ? '0px' : '30px' }}>
              #{this.state.groupPosition} - {this.state.language === 'sl' ? this.state.groupnamesl : this.state.groupname} {this.state.grouppos}
            </span>
          </div>
          : null}

        {this.state.user ?
          <div style={
            w < h && w <= 1024 ?
              { position: 'absolute', top: w + 5, left: 5, right: 5, marginLeft: 'auto', marginRight: 'auto', width: w - 10, height: '30px', padding: '0px' } :
              w > h && w <= 1024 ?
                { position: 'absolute', top: 50, left: h, right: 0, marginLeft: 'auto', marginRight: 'auto', width: 'calc(100% - ' + h + 'px)', height: '30px', padding: '0px' } :
                { position: 'absolute', bottom: 17, top: 'auto', left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto', width: (vh - 100) + 'px', height: '30px', padding: '0px' }}>
            <FaIcon title = {messages[this.state.language].signout} style={w < h && w <= 1024 ? { position: 'absolute', top: 35, fontSize: '24px', right: 0 } : w > h && w <= 1024 ? { position: 'absolute', bottom: -h + 83, fontSize: '24px', right: 0 } : { marginTop: '5px', display: 'block', float: 'left', fontSize: '24px' }} onClick={() => { this.chess.clear(); this.setState({ user: undefined, customeruuid: undefined, fen: undefined }) }} type="solid" icon="sign-out-alt" />
            <div style={{ float: 'right', fontWeight: 700, fontSize: '24px', marginBottom: '3px' }}>
              <span style = {{marginRight: '3px'}} title = {messages[this.state.language].instruction}>{this.state.playForWin ? this.state.playForMate ? messages[this.state.language].playForMate : messages[this.state.language].playForWin : messages[this.state.language].playForDraw}</span>
              <FaIcon title = {this.state.turn === 'w' ? messages[this.state.language].white : messages[this.state.language].black} type={this.state.turn === 'w' ? 'regular' : 'solid'} icon="square" />
              <span style = {{marginLeft: '3px'}} title = {messages[this.state.language].user}>{this.state.user}</span>
            </div>
          </div> : null}

        {this.state.user && this.state.promotion ?
          <div style={
            w < h && w <= 1024 ?
              { position: 'absolute', top: w + 80, left: 4, right: 4, marginLeft: 'auto', marginRight: 'auto', height: '88px', padding: '0px' } :
              w > h && w <= 1024 ?
                { position: 'absolute', top: 100, left: h + 4, right: 4, marginLeft: 'auto', marginRight: 'auto', height: '68px', padding: '0px' } :

                { position: 'absolute', top: 10, right: 0, marginLeft: 'auto', marginRight: 'auto', width: '272px', height: '68px', padding: '0px' }}>
            <div onClick={() => this.promote('q')} style={{ cursor: 'pointer', float: 'left' }}><img width={w < h && w <= 1024 ? 88 : 68} height={w < h && w <= 1024 ? 88 : 68} src={this.state.orientation === 'w' ? queen : Queen} /></div>
            <div onClick={() => this.promote('r')} style={{ cursor: 'pointer', float: 'left' }}><img width={w < h && w <= 1024 ? 88 : 68} height={w < h && w <= 1024 ? 88 : 68} src={this.state.orientation === 'w' ? rook : Rook} /></div>
            <div onClick={() => this.promote('n')} style={{ cursor: 'pointer', float: 'left' }}><img width={w < h && w <= 1024 ? 88 : 68} height={w < h && w <= 1024 ? 88 : 68} src={this.state.orientation === 'w' ? knight : Knight} /></div>
            <div onClick={() => this.promote('b')} style={{ cursor: 'pointer', float: 'left' }}><img width={w < h && w <= 1024 ? 88 : 68} height={w < h && w <= 1024 ? 88 : 68} src={this.state.orientation === 'w' ? bishop : Bishop} /></div>
          </div> : null}
        {this.state.groupPosition === 3 ?
        <>
          <div style = {
            w < h && w <= 1024 ?
            {display: 'none'}
            :
            w > h && w <= 1024 ?
            {display: 'none'}
            :
            {position: 'absolute', left: 25, width, height: 'calc(100% - 100px)', background: '#F0D9B5', padding: '5px', border: '1px solid #999', borderRadius: '10px'}}
          >
            {this.getStrategyText(0, this.state.getPosition.data.textenglong ? this.state.getPosition.data.textenglong : '-')}
          </div>
        
          <div style = {
            w < h && w <= 1024 ?
            {display: 'none'}
            :
            w > h && w <= 1024 ?
            {display: 'none'}
            :
            {position: 'absolute', right: 25, top: 50, width, height: 'calc(50% - 55px)', background: '#B58863', color: '#fff', padding: '5px', border: '1px solid #999', borderRadius: '10px'}}
          >
            {this.state.getPosition.data.textslshort ? this.state.getPosition.data.textslshort : '-'}
          </div>
          <div style = {
            w < h && w <= 1024 ?
            {display: 'none'}
            :
            w > h && w <= 1024 ?
            {display: 'none'}
            :
            {position: 'absolute', right: 25, bottom: 50, width, height: 'calc(50% - 55px)', background: '#B58863', color: '#fff', padding: '5px', border: '1px solid #999', borderRadius: '10px'}}
          >
            {this.state.getPosition.data.textengshort ? this.state.getPosition.data.textengshort : '-'}
          </div>
        </>
        : null}      
        {this.state.user ? null :
          <div style={
            w < h && w <= 1024 ?
              { position: 'absolute', left: 10, width: w - 20, top: w + 10 } :
              w > h && w <= 1024 ?
                { position: 'absolute', left: h + 10, width: 'calc(100% - ' + (h + 20) + 'px', top: 40 } :

                {
                  position: 'absolute', right: 25, width, height: 'calc(100% - 100px)',
                  background: 'transparent', padding: '10px'
                }}
          >
            <form onSubmit={this.onLogin}>
              <div className="clearfix">
                <FaIcon style={{ display: 'block', float: 'left', fontSize: '22px' }} type="solid" icon="user" />
                <input onChange={(e) => this.setDataValue('username', e.target.value)} style={{ fontSize: '14px', display: 'block', float: 'right', height: '25px', width: 'calc(100% - 30px)', background: '#fff', borderRadius: '3px', color: '#333', paddingLeft: '5px' }} type="text" value={this.state.username} />
              </div>
              <div style={{ marginTop: '5px', marginBottom: '5px' }} className="clearfix">
                <FaIcon style={{ display: 'block', float: 'left', fontSize: '22px' }} type="solid" icon="lock" />
                <input onChange={(e) => this.setDataValue('password', e.target.value)} style={{ fontSize: '14px', display: 'block', float: 'right', height: '25px', width: 'calc(100% - 30px)', background: '#fff', borderRadius: '3px', color: '#333', paddingLeft: '5px' }} type="password" value={this.state.password} />
              </div>
              <LoadingButton
                className="button dark-grey"
                isLoading={this.state.isLoading}
                type="submit"
              >
                <FaIcon type="solid" icon="sign-in-alt" />
              </LoadingButton>
            </form>
            <form onSubmit={this.onRegister}>
              <div style={{ marginTop: '15px', marginBottom: '5px', textAlign: 'center' }}>
                <FaIcon style={{ fontSize: '18px' }} type="solid" icon="plus-circle" />
              </div>
              <div className="clearfix">
                <FaIcon style={{ display: 'block', float: 'left', fontSize: '22px' }} type="solid" icon="user" />
                <input onChange={(e) => this.setDataValue('newusername', e.target.value)} style={{ fontSize: '14px', display: 'block', float: 'right', height: '25px', width: 'calc(100% - 30px)', background: '#fff', borderRadius: '3px', color: '#333', paddingLeft: '5px' }} type="text" value={this.state.newusername} />
              </div>
              <div style={{ marginTop: '5px', marginBottom: '5px' }} className="clearfix">
                <FaIcon style={{ display: 'block', float: 'left', fontSize: '22px' }} type="solid" icon="at" />
                <input onChange={(e) => this.setDataValue('email', e.target.value)} style={{ fontSize: '14px', display: 'block', float: 'right', height: '25px', width: 'calc(100% - 30px)', background: '#fff', borderRadius: '3px', color: '#333', paddingLeft: '5px' }} type="text" value={this.state.email} />
              </div>
              <div style={{ marginTop: '5px', marginBottom: '5px' }} className="clearfix">
                <FaIcon style={{ display: 'block', float: 'left', fontSize: '22px' }} type="solid" icon="lock" />
                <input onChange={(e) => this.setDataValue('password1', e.target.value)} style={{ fontSize: '14px', display: 'block', float: 'right', height: '25px', width: 'calc(100% - 30px)', background: '#fff', borderRadius: '3px', color: '#333', paddingLeft: '5px' }} type="password" value={this.state.password1} />
              </div>
              <div style={{ marginTop: '5px', marginBottom: '5px' }} className="clearfix">
                <FaIcon style={{ display: 'block', float: 'left', fontSize: '22px' }} type="solid" icon="lock" />
                <input onChange={(e) => this.setDataValue('password2', e.target.value)} style={{ fontSize: '14px', display: 'block', float: 'right', height: '25px', width: 'calc(100% - 30px)', background: '#fff', borderRadius: '3px', color: '#333', paddingLeft: '5px' }} type="password" value={this.state.password2} />
              </div>
              <LoadingButton
                className="button dark-grey"
                isLoading={this.state.isLoading}
                type="submit"
              >
                <FaIcon type="solid" icon="user-plus" />
              </LoadingButton>
            </form>
          </div>
        }

        {this.props.children({
          squareStyles,
          position: fen,
          orientation: turn === 'b' ? 'black' : 'white',
          onMouseOverSquare: this.onMouseOverSquare,
          onMouseOutSquare: this.onMouseOutSquare,
          onDrop: this.onDrop,
          dropSquareStyle,
          onDragOverSquare: this.onDragOverSquare,
          onSquareClick: this.onSquareClick,
          onSquareRightClick: this.onSquareRightClick,
          arrows: this.state.arrows,
          squares: this.state.squares,
          squareColor: this.state.squareColor
        })}</div>;
  }
}

export default function WithMoveValidation() {
  const w = screen.width
  const h = screen.height

  function getArrowLine(ch: string): number {
    return ch.charCodeAt(0) - 'a'.charCodeAt(0)
  }
  interface Point {x: number, y: number}

  function getSquareCenter(line: number, rank: number): Point {
    return {x: line * 12.5 + 12.5/2, y: rank * 12.5 + 12.5/2}
  }

  function getSquareEdge(line: number, rank: number): Point {
    return {x: line * 12.5, y: rank * 12.5}
  }

  function getSquares(squares: StrategyColor[]): JSX.Element[] {
    const h2h = document.getElementById('h2h')
    let width = 0
    if (h2h) {
      width = h2h.clientWidth
    }
    const a: JSX.Element[] = []
    const borderWidth: number = 1/100 * width
    for (const square of squares) {
      const squareLine: number = getArrowLine(square.name.charAt(0))
      const squareRank: number = parseInt(square.name.charAt(1)) - 1
      const squareEdge: Point = getSquareEdge(squareLine, squareRank)
      a.push(<div key = {square.name} style = {{position: 'absolute', left: squareEdge.x + '%', bottom: squareEdge.y + '%', width: '12.5%', height: '12.5%', border: borderWidth + 'px solid ' + square.color}}>
        &nbsp;
      </div>)
    }

    return a
  }

  function getSquareColor(squares: StrategyColor[]): JSX.Element[] {
    const a: JSX.Element[] = []
    for (const square of squares) {
      const squareLine: number = getArrowLine(square.name.charAt(0))
      const squareRank: number = parseInt(square.name.charAt(1)) - 1
      const squareEdge: Point = getSquareEdge(squareLine, squareRank)
      a.push(<div key = {square.name} style = {{position: 'absolute', left: squareEdge.x + '%', bottom: squareEdge.y + '%', width: '12.5%', height: '12.5%', background: square.color}}>
        &nbsp;
      </div>)
    }

    return a
  }

/*

eng long {2E4,3E5} <{le4,me5}[ng1f3,ob1c3]>
[jg1f3,kb1c3,la4d4,ka1a3,mh8h5,ng8f6,ob8c6,pg5e5,qa5d5] rerere <{le4,me5}[ng1f3,ob1c3]>
<[ng1f3,ob1c3]{le4,me5}>

*/

  function getArrows(arrows: StrategyArrow[]): JSX.Element[] {
    const h2h = document.getElementById('h2h')
    let width = 0
    if (h2h) {
      width = h2h.clientWidth
    }

    const PI = 3.14159265359
    const a: JSX.Element[] = []
    const arrowWidth = 2 // percentage
    for (const arrow of arrows) {
      const arrowStartLine: number = getArrowLine(arrow.startSquare.charAt(0))
      const arrowStartRank: number = parseInt(arrow.startSquare.charAt(1)) - 1
      const arrowStart: Point = getSquareCenter(arrowStartLine, arrowStartRank)
      const arrowEndLine: number = getArrowLine(arrow.endSquare.charAt(0))
      const arrowEndRank: number = parseInt(arrow.endSquare.charAt(1)) - 1
      const arrowEnd: Point = getSquareCenter(arrowEndLine, arrowEndRank)
      const diffX: number = arrowEnd.x - arrowStart.x
      const diffY: number = arrowEnd.y - arrowStart.y
      const arrowLength: number = Math.sqrt(diffX * diffX + diffY * diffY)
      const an: number = diffY ? diffX / diffY : 0
      let angle = 0
      if (diffY > 0) {
          const sin: number = Math.sin(an)
          angle = sin
      } else if (diffY < 0) {
          const sin: number = Math.sin(an)
          angle = sin + PI
      } else if (diffY === 0) {
        angle = diffX > 0 ? PI/2 : -PI/2
      }
      a.push(<div style = {{
          transformOrigin: 'bottom center', transform: 'rotate(' + angle + 'rad)',
          position: 'absolute', zIndex: 9999, background: arrow.color, width: arrowWidth + '%',
          height: 'calc(' + arrowLength + '% - 20px)', bottom: arrowStart.y + '%', left: (arrowStart.x - arrowWidth/2) + '%'
        }}
        key = {arrow.startSquare + arrow.endSquare}>
        &nbsp;
      </div>)
      const borderWidth: number = width / 32.2
      a.push(<div style = {{transformOrigin: 'bottom center', transform: 'rotate(' + angle + 'rad) translate(0,' + (-(arrowLength / 100 * width - borderWidth - 1)) + 'px)',
        zIndex: 9999, width: 0, height: 0, borderLeft: borderWidth + 'px solid transparent', borderRight: borderWidth + 'px solid transparent',
        borderBottom: borderWidth + 'px solid ' + arrow.color, position: 'absolute', bottom: arrowStart.y + '%', left: 'calc(' + arrowStart.x + '% - ' + borderWidth + 'px)'
        }} key = {arrow.startSquare + '_' + arrow.endSquare}>
        &nbsp;
      </div>)
    }

    return a
  }

  return <div style={
    w < h && w <= 1024 ? { position: 'absolute', left: 0, right: 0, top: 30 } :
      w > h && w <= 1024 ? { position: 'absolute', left: 0, right: 0, top: 0 } :
        {}}>
    <HumanVsHuman>
      {({
        position,
        onDrop,
        onMouseOverSquare,
        onMouseOutSquare,
        squareStyles,
        dropSquareStyle,
        onDragOverSquare,
        onSquareClick,
        onSquareRightClick,
        orientation,
        arrows,
        squares,
        squareColor
      }) =>
        <div style = {{position: 'relative'}}>
          <Chessboard
            id='humanVsHuman' // https://www.chessboardjsx.com/props
            calcWidth={({ /*screenWidth, */screenHeight }) => w < h && w <= 1024 ? w : h < w && w <= 1024 ? h : screenHeight - 100}
            position={position}
            orientation={orientation}
            onDrop={onDrop}
            onMouseOverSquare={onMouseOverSquare}
            onMouseOutSquare={onMouseOutSquare}
            boardStyle={{
              borderRadius: '5px',
              boxShadow: `0 5px 15px rgba(0, 0, 0, 0.5)`
            }}
            squareStyles={squareStyles}
            dropSquareStyle={dropSquareStyle}
            onDragOverSquare={onDragOverSquare}
            onSquareClick={onSquareClick}
            onSquareRightClick={onSquareRightClick}
          />
          {getSquareColor(squareColor)}
          {getSquares(squares)}
          {getArrows(arrows)}
        </div>
      }

    </HumanVsHuman>
  </div>

}

const squareStyling = ({ pieceSquare, history, matingSquare, stalemateSquare, squareColor}) => {
  const sourceSquare = history.length && history[history.length - 1] && history[history.length - 1].from;
  const targetSquare = history.length && history[history.length - 1] && history[history.length - 1].to;
  const squarecolor = {
      [pieceSquare]: { backgroundColor: 'rgba(0, 255, 255, 0.4)' },
    ...(history.length && {
      [sourceSquare]: {
        backgroundColor: 'rgba(0, 255, 255, 0.4)'
      }
    }),
    ...(history.length && {
      [targetSquare]: {
        backgroundColor: 'rgba(0, 255, 255, 0.4)'
      }
    })
  }

  if (matingSquare.length) {
      squarecolor[matingSquare] = { backgroundColor: 'crimson' }
  } else if (stalemateSquare.length) {
      squarecolor[stalemateSquare] = { backgroundColor: 'coral' }
/* 
  } else if (squareColor && squareColor.length) {
    for (const item of squareColor) {
      squarecolor[item.name] = {backgroundColor: item.color}
    }
*/
  }

/* https://www.99colors.net/color-names
function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

hexToRGB('#FF0000', 0.5);

*/

  return squarecolor
  /*
  matingSquare.length ?
    {
      [matingSquare]: { backgroundColor: 'crimson' },

      [pieceSquare]: { backgroundColor: 'rgba(0, 255, 255, 0.4)' },
      ...(history.length && {
        [sourceSquare]: {
          backgroundColor: 'rgba(0, 255, 255, 0.4)'
        }
      }),
      ...(history.length && {
        [targetSquare]: {
          backgroundColor: 'rgba(0, 255, 255, 0.4)'
        }
      })
    }
    :
    stalemateSquare.length ?
      {
        [stalemateSquare]: { backgroundColor: 'coral' },

        [pieceSquare]: { backgroundColor: 'rgba(0, 255, 255, 0.4)' },
        ...(history.length && {
          [sourceSquare]: {
            backgroundColor: 'rgba(0, 255, 255, 0.4)'
          }
        }),
        ...(history.length && {
          [targetSquare]: {
            backgroundColor: 'rgba(0, 255, 255, 0.4)'
          }
        })
      }
    :
    */
    // squarecolor
/*
    squareColor.length ?
    squarecolor
    :
    {
      [pieceSquare]: { backgroundColor: 'rgba(0, 255, 255, 0.4)' },
      ...(history.length && {
        [sourceSquare]: {
          backgroundColor: 'rgba(0, 255, 255, 0.4)'
        }
      }),
      ...(history.length && {
        [targetSquare]: {
          backgroundColor: 'rgba(0, 255, 255, 0.4)'
        }
      })
    };
*/
};
