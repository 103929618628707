import React from 'react';
import clsx from 'clsx';

export type FaIconType = 'solid' | 'regular' | 'brand'

interface Props {
    icon: string
    type: FaIconType
    className?: string
    id?: string
    style?: React.CSSProperties
    title?: string
    onClick?: (e?: any) => void
}

function FaIcon({ className, icon, id, onClick, style, title, type }: Props) {
    const prefix: string = (type === 'solid') ? 'fas' : (type === 'regular') ? 'far' : 'fab'
    const originalClassName = `${prefix} fa-${icon}`
    const originalStyle = (onClick) ? {...style, cursor: 'pointer'} : style
    
    return (
        <i
            className={clsx(originalClassName, className && className)}
            id={id}
            style={originalStyle}
            onClick={onClick}
            title={title}
        />
    )
}

export default FaIcon;